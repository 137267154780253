import React from 'react';
import { object, bool, func } from 'prop-types';
import classNames from 'classnames';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Tooltip,
  ListItemIcon
} from '@mui/material';
import { withRouter, Link } from 'react-router-dom';
import { ExitToApp } from '@mui/icons-material';
import { isLoggedInShop } from '../../services/authService';
import { SITE_PRIVATE_BASE_PATH } from './public-site-utils';
import LogoSitio from './components/LogoSitio';
import classes from './PublicSiteSideBar.module.scss';

const PublicSiteSideBar = ({ handleDrawerClose, openDrawer }) => {
  return (
    <Drawer
      variant={'temporary'}
      className={classNames(classes.drawer)}
      open={openDrawer}
      onClose={handleDrawerClose}
    >
      <LogoSitio className={classes.hideLarge} classNameImg={classes.logo} />
      <Divider />
      <List>
        <Tooltip title="INICIO" placement="right">
          <Link to={`/home`} style={{ textDecoration: 'none' }}>
            <ListItem id="home-PublicSiteSideBar-menu" button onClick={handleDrawerClose}>
              <ListItemText
                primary={'INICIO'}
                primaryTypographyProps={{ className: classes.itemText }}
              />
            </ListItem>
          </Link>
        </Tooltip>
        <Tooltip title="NOSOTROS" placement="right">
          <Link to={`/whoweare`} style={{ textDecoration: 'none' }}>
            <ListItem id="who-PublicSiteSideBar-menu" button onClick={handleDrawerClose}>
              <ListItemText
                primary={'Nosotros'}
                primaryTypographyProps={{ className: classes.itemText }}
              />
            </ListItem>
          </Link>
        </Tooltip>
        {/* <Tooltip title="MARCAS" placement="right">
          <Link to={`/brands`} style={{ textDecoration: 'none' }}>
            <ListItem id="brands-PublicSiteSideBar-menu" button onClick={handleDrawerClose}>
              <ListItemText
                primary={'Marcas'}
                primaryTypographyProps={{ className: classes.itemText }}
              />
            </ListItem>
          </Link>
        </Tooltip> */}
        <Tooltip title="CATÁLOGO" placement="right">
          <Link
            to={{
              pathname: `${SITE_PRIVATE_BASE_PATH}catalog/1`,
              state: { redirectTo: `${SITE_PRIVATE_BASE_PATH}catalog/1` }
            }}
            style={{ textDecoration: 'none' }}
          >
            <ListItem id="clients-PublicSiteSideBar-menu" button onClick={handleDrawerClose}>
              <ListItemText
                primary={'CATÁLOGO'}
                primaryTypographyProps={{ className: classes.itemText }}
              />
            </ListItem>
          </Link>
        </Tooltip>
        <Tooltip title="CONTACTO" placement="right">
          <Link to={`/contact`} style={{ textDecoration: 'none' }}>
            <ListItem id="contact-PublicSiteSideBar-menu" button onClick={handleDrawerClose}>
              <ListItemText
                primary={'Contacto'}
                primaryTypographyProps={{ className: classes.itemText }}
              />
            </ListItem>
          </Link>
        </Tooltip>

        {isLoggedInShop() && (
          <>
            <Divider />
            <Tooltip title="CERRAR SESIÓN" placement="right">
              <Link to={`/logout`} style={{ textDecoration: 'none' }}>
                <ListItem button onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <ExitToApp color="secondary" className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Cerrar Sessión'}
                    primaryTypographyProps={{ className: classes.itemText }}
                  />
                </ListItem>
              </Link>
            </Tooltip>
          </>
        )}
      </List>
    </Drawer>
  );
};

PublicSiteSideBar.propTypes = {
  classes: object,
  openDrawer: bool,
  handleDrawerClose: func,
  theme: object,
  history: object
};

export default withRouter(PublicSiteSideBar);
