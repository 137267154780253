import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@mui/material/SvgIcon';
import { object, string } from 'prop-types';

let OilBarrelIcon = (props) => (
  <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <defs />
    <path
      fill="#375e7d"
      fillRule="evenodd"
      d="M290.88 47.61L334 68l35.794-20.39V13.084c0-3.061-2.523-5.584-5.612-5.584h-67.69c-3.09 0-5.612 2.523-5.612 5.584zM118.056 465.665L260 484l133.945-18.335v-62.504L259 381l-140.944 22.161z"
      clipRule="evenodd"
    />
    <path
      fill="#375e7d"
      fillRule="evenodd"
      d="M118.056 364.326L260 387l133.945-22.674v-176.57L259 159l-140.945 28.756v176.57z"
      clipRule="evenodd"
    />
    <path
      fill="#375e7d"
      fillRule="evenodd"
      d="M118.056 148.921L259 181l134.945-32.079V86.445L258 69 118.056 86.445v35.547z"
      clipRule="evenodd"
    />
    <path
      fill="#f9f7f8"
      fillRule="evenodd"
      d="M107.256 468.953c-5.187 3.487-8.617 9.439-8.617 16.129 0 10.658 8.731 19.417 19.417 19.417h275.889c10.686 0 19.417-8.759 19.417-19.417 0-6.69-3.43-12.643-8.617-16.129a19.352 19.352 0 00-10.8-3.288H118.056a19.35 19.35 0 00-10.8 3.288zM118.056 403.161h275.889c10.686 0 19.417-8.731 19.417-19.417s-8.731-19.417-19.417-19.417H118.056c-10.686 0-19.417 8.731-19.417 19.417s8.73 19.417 19.417 19.417z"
      clipRule="evenodd"
    />
    <path
      fill="#ffd772"
      fillRule="evenodd"
      d="M218.541 292.581c0 24.463 18.737 36.709 37.473 36.709 18.708 0 37.445-12.246 37.445-36.709 0-18.879-24.264-51.818-34.412-67.068-2.409-3.628-3.685-3.628-6.094 0-10.148 15.251-34.412 48.189-34.412 67.068z"
      clipRule="evenodd"
    />
    <path
      fill="#f9f7f8"
      fillRule="evenodd"
      d="M118.056 187.756h275.889c10.686 0 19.417-8.731 19.417-19.417 0-10.658-8.731-19.417-19.417-19.417H118.056c-10.686 0-19.417 8.759-19.417 19.417 0 10.686 8.73 19.417 19.417 19.417zM118.056 86.445h275.889c10.686 0 19.417-8.731 19.417-19.417s-8.731-19.417-19.417-19.417H118.056c-10.686 0-19.417 8.731-19.417 19.417s8.73 19.417 19.417 19.417z"
      clipRule="evenodd"
    />
    <path
      fill="#e5e4ec"
      fillRule="evenodd"
      d="M363.502 67.028c0 10.687-8.759 19.417-19.417 19.417h49.86c10.686 0 19.417-8.731 19.417-19.417s-8.731-19.417-19.417-19.417H344.084c10.659-.001 19.418 8.73 19.418 19.417z"
      clipRule="evenodd"
    />
    <path
      fill="#2b4d66"
      fillRule="evenodd"
      d="M393.945 86.445h-49.86v62.476h49.86v-26.929z"
      clipRule="evenodd"
    />
    <path
      fill="#e5e4ec"
      fillRule="evenodd"
      d="M413.362 168.338c0-10.658-8.731-19.417-19.417-19.417h-49.86c10.658 0 19.417 8.759 19.417 19.417 0 10.687-8.759 19.417-19.417 19.417h49.86c10.686.001 19.417-8.73 19.417-19.417z"
      clipRule="evenodd"
    />
    <path
      fill="#2b4d66"
      fillRule="evenodd"
      d="M393.945 277.699v-89.943h-49.86v176.57h49.86v-51.08z"
      clipRule="evenodd"
    />
    <path
      fill="#e5e4ec"
      fillRule="evenodd"
      d="M393.945 364.326h-49.86c10.658 0 19.417 8.731 19.417 19.417s-8.759 19.417-19.417 19.417h49.86c10.686 0 19.417-8.731 19.417-19.417s-8.731-19.417-19.417-19.417z"
      clipRule="evenodd"
    />
    <path
      fill="#2b4d66"
      fillRule="evenodd"
      d="M344.085 403.161h49.86v62.504h-49.86z"
      clipRule="evenodd"
    />
    <path
      fill="#e5e4ec"
      fillRule="evenodd"
      d="M393.945 465.665h-49.86c3.968 0 7.682 1.219 10.771 3.288 5.216 3.487 8.645 9.439 8.645 16.129 0 10.658-8.759 19.417-19.417 19.417h49.86c10.686 0 19.417-8.759 19.417-19.417 0-6.69-3.43-12.643-8.617-16.129a19.346 19.346 0 00-10.799-3.288z"
      clipRule="evenodd"
    />
    <path
      fill="#fbba58"
      fillRule="evenodd"
      d="M256.014 329.29c18.708 0 37.445-12.246 37.445-36.709 0-18.879-24.264-51.818-34.412-67.068-2.409-3.628-3.685-3.628-6.094 0-2.863 4.309-6.86 10.035-11.112 16.469 10.8 16.299 23.272 37.049 23.272 50.599 0 18.142-10.318 29.594-23.272 34.271 4.508 1.616 9.326 2.438 14.173 2.438z"
      clipRule="evenodd"
    />
    <path
      fill="#2b4d66"
      fillRule="evenodd"
      d="M342.412 13.084V47.61h27.382V13.084c0-3.061-2.523-5.584-5.612-5.584H336.8c3.09 0 5.612 2.523 5.612 5.584z"
      clipRule="evenodd"
    />
    <path d="M269.545 227.641a751.732 751.732 0 01-4.25-6.276c-1.103-1.661-4.032-6.073-9.294-6.073-5.256 0-8.186 4.401-9.292 6.066a691.622 691.622 0 01-4.258 6.289c-15.893 23.306-31.41 47.518-31.41 64.934 0 29.023 22.625 44.209 44.973 44.209 22.334 0 44.945-15.186 44.945-44.209 0-17.418-15.518-41.632-31.414-64.94zm-13.531 94.149c-14.919 0-29.973-9.032-29.973-29.209 0-14.247 18.736-41.722 28.802-56.483l1.157-1.697 1.152 1.691c10.067 14.763 28.807 42.241 28.807 56.489 0 20.177-15.04 29.209-29.945 29.209z" />
    <path d="M420.862 168.338c0-12.239-8.214-22.59-19.417-25.846V92.874c11.203-3.256 19.417-13.607 19.417-25.846 0-14.842-12.075-26.917-26.917-26.917h-16.65V13.084C377.294 5.87 371.412 0 364.182 0h-67.689c-7.23 0-13.112 5.87-13.112 13.084V40.11H118.055c-14.842 0-26.917 12.075-26.917 26.917 0 12.24 8.214 22.59 19.417 25.846v49.618c-11.203 3.256-19.417 13.607-19.417 25.846s8.214 22.59 19.417 25.846v163.713c-11.203 3.256-19.417 13.607-19.417 25.846s8.214 22.59 19.417 25.846v49.646c-11.203 3.256-19.417 13.607-19.417 25.846 0 14.842 12.075 26.917 26.917 26.917h275.889c14.842 0 26.917-12.075 26.917-26.917 0-12.239-8.214-22.59-19.417-25.846V409.59c11.203-3.256 19.417-13.607 19.417-25.846s-8.214-22.59-19.417-25.846V194.185c11.203-3.257 19.418-13.607 19.418-25.847zM125.555 356.826v-161.57h260.889v161.57zM298.38 15h63.914v25.11H298.38zM106.138 67.028c0-6.571 5.346-11.917 11.917-11.917h275.889c6.571 0 11.917 5.346 11.917 11.917s-5.346 11.917-11.917 11.917H276.736c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h109.709v47.476h-260.89V93.945h116.541a7.5 7.5 0 000-15H118.055c-6.571 0-11.917-5.346-11.917-11.917zm0 101.31c0-6.571 5.346-11.917 11.917-11.917h275.889c6.571 0 11.917 5.346 11.917 11.917s-5.346 11.917-11.917 11.917H118.055c-6.571.001-11.917-5.345-11.917-11.917zm299.724 316.745c0 6.571-5.346 11.917-11.917 11.917h-275.89c-6.571 0-11.917-5.346-11.917-11.917s5.346-11.917 11.917-11.917h124.041a7.5 7.5 0 000-15H125.555v-47.504h260.889v47.504H276.736c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h117.209c6.571-.001 11.917 5.345 11.917 11.917zm0-101.339c0 6.571-5.346 11.917-11.917 11.917h-275.89c-6.571 0-11.917-5.346-11.917-11.917s5.346-11.917 11.917-11.917h275.889c6.572-.001 11.918 5.345 11.918 11.917z" />
  </SvgIcon>
);

OilBarrelIcon = pure(OilBarrelIcon);

OilBarrelIcon.displayName = 'StatusCheckCircle2';

OilBarrelIcon.muiName = 'SvgIcon';

OilBarrelIcon.propTypes = {
  styles: object,
  viewBox: string
};

export default OilBarrelIcon;
