"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapTaxToAfipEnum = void 0;

var _Enums = require("../enums/Enums");

var mapTaxToAfipEnum = function mapTaxToAfipEnum(tax) {
  switch (tax) {
    case 0:
      return _Enums.EnumAfipIvaType.IVA_0;

    case 21:
      return _Enums.EnumAfipIvaType.IVA_21;

    case 27:
      return _Enums.EnumAfipIvaType.IVA_27;

    case 10.5:
      return _Enums.EnumAfipIvaType.IVA_10_5;

    case 2.5:
      return _Enums.EnumAfipIvaType.IVA_2_5;

    case 5:
      return _Enums.EnumAfipIvaType.IVA_5;
  }
};

exports.mapTaxToAfipEnum = mapTaxToAfipEnum;