import {
  UPDATE_USER_PROFILE_ACTION,
  CLEAR_USER_PROFILE_ACTION,
  SET_APOLLO_CLIENT,
  SET_SEARCH_FILTER,
  CLEAR_SEARCH_FILTER,
  SET_OPESI_FILTER,
  CLEAR_OPESI_FILTER,
  SET_OFFLINE_MODE,
  ADD_TRACKED_QUERY,
  REMOVE_TRACKED_QUERY,
  SET_THEME_MODE
} from './action-types';

const initialState = {
  userProfileData: null,
  apolloClient: null,
  searchFilter: null,
  opesi: false,
  offline: false,
  trackedQueries: [],
  theme: null
};

/**
 A reducer is a JavaScript function taking two parameters: state and action. A reducer function may use a switch statement 
 (but I prefer using if) for handling every action type.
The reducer calculates the next state depending on the action type. Moreover, it should return at least the initial state 
when no action type matches.
 */
function rootReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER_PROFILE_ACTION: {
      const newPayload = { ...action.payload };
      if (
        action.payload &&
        action.payload.settings &&
        typeof action.payload.settings === 'string'
      ) {
        newPayload.settings =
          action.payload && action.payload.settings ? JSON.parse(action.payload.settings) : null;
      }
      return { ...state, userProfileData: newPayload };
    }

    case CLEAR_USER_PROFILE_ACTION:
      return { ...state, userProfileData: null };

    case SET_APOLLO_CLIENT:
      return { ...state, apolloClient: action.payload };

    case SET_SEARCH_FILTER:
      return { ...state, searchFilter: action.payload };

    case CLEAR_SEARCH_FILTER:
      return { ...state, searchFilter: null };

    case SET_OPESI_FILTER:
      return { ...state, opesi: !state.opesi };

    case CLEAR_OPESI_FILTER:
      return { ...state, opesi: false };

    case SET_OFFLINE_MODE:
      return { ...state, offline: action.payload };

    case ADD_TRACKED_QUERY: {
      const newState = {
        ...state,
        trackedQueries: [...state.trackedQueries, action.payload]
      };
      return newState;
    }
    case REMOVE_TRACKED_QUERY: {
      const newState = {
        ...state
      };

      const index = newState.trackedQueries.findIndex((item) => item.id === action.payload);
      if (index === -1) return state;

      newState.trackedQueries = newState.trackedQueries
        .slice(0, index)
        .concat(newState.trackedQueries.slice(index + 1));

      return newState;
    }

    case SET_THEME_MODE:
      return { ...state, theme: action.payload };

    default:
      return state;
  }
}

export default rootReducer;
