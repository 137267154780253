import React from 'react';
import { object, func } from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Column } from 'simple-flexbox';
import * as media from '../styles/media-queries';
import * as colors from '../styles/colors';
import { browserHistory, PRIVATE_BASE_PATH } from '../globals';

const styles = () => ({
  root: {
    ...media.hideOnPrint
  },
  offlineBanner: {
    height: 30,
    width: '100%',
    backgroundColor: colors.red,
    padding: 8,
    color: colors.white,
    marginBottom: 10
  }
});

const BreadCrumb = (props) => {
  const { classes } = props;
  // const offline = useSelector((state) => state.offline);
  const notHasBread = [`${PRIVATE_BASE_PATH}dashboard`, 'print', 'login', 'paymentinvoice'].some(
    (route) => {
      return location.pathname.indexOf(route) !== -1;
    }
  );
  if (notHasBread) {
    return null;
  }
  return (
    <div className={classes.root} style={{ width: '100%' }}>
      <Column style={{ width: '100%' }} horizontal="start">
        <Button onClick={() => browserHistory.goBack()}>
          <ArrowBack style={{ marginRight: 5 }} />
          ATRÁS
        </Button>
      </Column>
    </div>
  );
};

BreadCrumb.propTypes = {
  classes: object,
  onBack: func
};

export default withStyles(styles)(BreadCrumb);

/* <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="Breadcrumb">
<Link color="inherit" href="/" onClick={handleClick}>
  Example
</Link>
<Link color="inherit" href="/getting-started/installation/" onClick={handleClick}>
  Core
</Link>
<Typography color="textPrimary">Breadcrumb</Typography>
</Breadcrumbs> */
