import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import shortLogo from '../assets/short-logo-sitio.png';
import shortLogoOpesiImg from '../../../../public/shortlogo.png';
// import shortLogoAlt from '../assets/short-logo-sitio-inv.png';
import logoAltHQ from '../assets/logo-sitio-inv.png';
import logoHQ from '../assets/logo-sitio.png';
import classes from './LogoSitio.module.scss';

const LogoSitio = ({
  externalLink,
  logoLink,
  className,
  classNameImg,
  style,
  styleImg,
  shortVersion,
  shortVersionOpesi,
  alt
}) => {
  const getLogoImg = () => {
    // TODO hacer una funcion q agarre el theme con hook xq no uso mas with styles
    // if (isDark(theme)) return logoAltHQ;
    if (shortVersion) {
      return shortLogo;
    } else if (alt) {
      return logoAltHQ;
    }
    if (shortVersionOpesi) {
      return shortLogoOpesiImg;
    }
    return logoHQ;
  };
  const Img = (
    <img
      alt="Logo"
      src={getLogoImg()}
      className={classnames(classes.logo, { [classes.shortLogoPrint]: shortVersion }, classNameImg)}
      style={{ ...styleImg }}
    />
  );
  return (
    <div className={classnames(className)} style={{ ...style }}>
      {externalLink !== undefined ? (
        <a href={externalLink} target="_blank" rel="noreferrer noopener">
          {Img}
        </a>
      ) : (
        <Link to={logoLink}>{Img}</Link>
      )}
    </div>
  );
};

LogoSitio.propTypes = {
  externalLink: PropTypes.string,
  logoLink: PropTypes.string,
  className: PropTypes.string,
  classNameImg: PropTypes.string,
  style: PropTypes.object,
  styleImg: PropTypes.object,
  shortVersion: PropTypes.bool,
  alt: PropTypes.bool,
  classes: PropTypes.object,
  theme: PropTypes.object,
  hightQuality: PropTypes.bool,
  shortVersionOpesi: PropTypes.bool
};

LogoSitio.defaultProps = {
  logoLink: '/'
};

export default LogoSitio;
