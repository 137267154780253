export const CATALOG_CART_REVENUE_KEY = 'ries-public-catalog-revenue';

function supportsLocalStorage() {
  const mod = 'modernizr';
  try {
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return true;
  } catch (e) {
    return false;
  }
}

const supportsLS = supportsLocalStorage();
const memoryStorage = {};

export function setItem(name, value) {
  if (supportsLS) {
    window.localStorage.setItem(name, value);
  } else {
    memoryStorage[name] = value;
  }
}

export function getItem(name) {
  if (supportsLS) {
    return window.localStorage.getItem(name);
  } else {
    return memoryStorage[name];
  }
}

export const getTrackedQueries = () => JSON.parse(getItem('trackedQueries') || null) || [];

export const setTrackedQueries = (queries) => setItem('trackedQueries', queries);

export const getSavedSell = () => {
  const sell = getItem('ries-saved-sell');
  return sell ? JSON.parse(sell) : null;
};

export const setSavedSell = (sell) => setItem('ries-saved-sell', JSON.stringify(sell));

export const getCatalogRevenueFromLS = () =>
  getItem(CATALOG_CART_REVENUE_KEY) && new Map(JSON.parse(getItem(CATALOG_CART_REVENUE_KEY)));

export const setCatalogRevenueToLS = (data) =>
  setItem(CATALOG_CART_REVENUE_KEY, JSON.stringify(Array.from(data)));
