import React, { lazy } from 'react';
import { object } from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import PageNotFound from '../utils/common-components/PageNotFound';
const ShopIndex = lazy(() => import('../pages/ecommerce/ShopIndex'));

const ShopRoutes = (props) => {
  const me = props.match.url;
  return (
    <Switch>
      <Route exact path={`${me}home`} component={ShopIndex} />
      <Route component={PageNotFound} />
    </Switch>
  );
};

ShopRoutes.propTypes = {
  match: object
};

export default ShopRoutes;
