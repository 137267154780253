"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFundsTransferTypeName = getFundsTransferTypeName;
exports.isInboundOperation = isInboundOperation;
exports.roleOptions = exports.translateRoleLabel = exports.getPaymentScheduleStatusLabel = exports.taxTypeAsOptions = exports.getTaxTypeLabel = exports.docTypeAsOptions = exports.clientTaxCategoryAsOptions = exports.getFundsTransferOperationsOptions = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _Enums = require("./Enums");

function getFundsTransferTypeName(type) {
  if (!type) return '';

  switch (type) {
    case _Enums.EnumFundsTransferType.SELL:
      return 'VENTA';

    case _Enums.EnumFundsTransferType.SELL2:
      return 'VENTA IVA';

    case _Enums.EnumFundsTransferType.QUOTE_REQUEST2:
      return 'VENTA CTA2';

    case _Enums.EnumFundsTransferType.CLIENT_PAYMENT:
      return 'COBRANZA';

    case _Enums.EnumFundsTransferType.CASH_EXTRACTION:
      return 'RETIRO';

    case _Enums.EnumFundsTransferType.SUPPLIER_PAYMENT:
      return 'PAGO PROVEEDORES';

    case _Enums.EnumFundsTransferType.CASH_INJECTION:
      return 'INGRESO EFT.';

    case _Enums.EnumFundsTransferType.EXPENSES:
      return 'GASTOS VARIOS';

    case _Enums.EnumFundsTransferType.BILLS:
      return 'SERVICIOS';

    case _Enums.EnumFundsTransferType.SOFTWARE_DEVELOPMENT:
      return 'DESARROLLO SOFTWARE';

    case _Enums.EnumFundsTransferType.IT_SERVICES:
      return 'HOSTING/SERVIDORES';

    case _Enums.EnumFundsTransferType.SALARY:
      return 'SUELDOS';

    case _Enums.EnumFundsTransferType.ALLOWANCES:
      return 'VIÁTICOS';

    case _Enums.EnumFundsTransferType.MAINTENANCE:
      return 'MANTENIMIENTO';

    case _Enums.EnumFundsTransferType.SALE_COMISSIONS:
      return 'COMISIONES VENTA';

    case _Enums.EnumFundsTransferType.INTERNAL_TRANSFER:
      return 'TRASPASO';

    case _Enums.EnumFundsTransferType.ACCOUNT_AMORTIZATION:
      return 'AMORTIZACION';

    case _Enums.EnumFundsTransferType.CN_APPLIED:
      return 'NC APLICADA';

    case _Enums.EnumFundsTransferType.FUEL_EXPENSE:
      return 'COMBUSTIBLE';

    default:
      return '';
  }
}

function isInboundOperation(type) {
  if (!type) return null;

  switch (type) {
    case _Enums.EnumFundsTransferType.SELL:
    case _Enums.EnumFundsTransferType.SELL2:
    case _Enums.EnumFundsTransferType.QUOTE_REQUEST2:
    case _Enums.EnumFundsTransferType.CLIENT_PAYMENT:
    case _Enums.EnumFundsTransferType.CASH_INJECTION:
    case _Enums.EnumFundsTransferType.INTERNAL_TRANSFER:
      return true;
  }

  return false;
}

var getFundsTransferOperationsOptions = function getFundsTransferOperationsOptions(expenses, isAdmin) {
  var options;

  if (expenses) {
    options = [{
      value: _Enums.EnumFundsTransferType.FUEL_EXPENSE,
      label: getFundsTransferTypeName(_Enums.EnumFundsTransferType.FUEL_EXPENSE)
    }, {
      value: _Enums.EnumFundsTransferType.EXPENSES,
      label: getFundsTransferTypeName(_Enums.EnumFundsTransferType.EXPENSES)
    }, {
      value: _Enums.EnumFundsTransferType.CASH_EXTRACTION,
      label: getFundsTransferTypeName(_Enums.EnumFundsTransferType.CASH_EXTRACTION)
    }, {
      value: _Enums.EnumFundsTransferType.BILLS,
      label: getFundsTransferTypeName(_Enums.EnumFundsTransferType.BILLS)
    }, {
      value: _Enums.EnumFundsTransferType.ALLOWANCES,
      label: getFundsTransferTypeName(_Enums.EnumFundsTransferType.ALLOWANCES)
    }, {
      value: _Enums.EnumFundsTransferType.MAINTENANCE,
      label: getFundsTransferTypeName(_Enums.EnumFundsTransferType.MAINTENANCE)
    }];

    if (isAdmin) {
      options = [].concat((0, _toConsumableArray2["default"])(options), [{
        value: _Enums.EnumFundsTransferType.SUPPLIER_PAYMENT,
        label: getFundsTransferTypeName(_Enums.EnumFundsTransferType.SUPPLIER_PAYMENT)
      }, {
        value: _Enums.EnumFundsTransferType.SOFTWARE_DEVELOPMENT,
        label: getFundsTransferTypeName(_Enums.EnumFundsTransferType.SOFTWARE_DEVELOPMENT)
      }, {
        value: _Enums.EnumFundsTransferType.IT_SERVICES,
        label: getFundsTransferTypeName(_Enums.EnumFundsTransferType.IT_SERVICES)
      }, {
        value: _Enums.EnumFundsTransferType.SALARY,
        label: getFundsTransferTypeName(_Enums.EnumFundsTransferType.SALARY)
      }, {
        value: _Enums.EnumFundsTransferType.SALE_COMISSIONS,
        label: getFundsTransferTypeName(_Enums.EnumFundsTransferType.SALE_COMISSIONS)
      }]);
    }
  } else {
    options = [{
      value: _Enums.EnumFundsTransferType.CASH_INJECTION,
      label: getFundsTransferTypeName(_Enums.EnumFundsTransferType.CASH_INJECTION)
    }];
  }

  return [{
    value: undefined,
    label: '-- SELECCIONE --'
  }].concat((0, _toConsumableArray2["default"])(options));
};

exports.getFundsTransferOperationsOptions = getFundsTransferOperationsOptions;
var clientTaxCategoryAsOptions = [{
  value: undefined,
  label: '-- SELECCIONE --'
}].concat((0, _toConsumableArray2["default"])(Object.keys(_Enums.EnumClientTaxCategory).map(function (k) {
  return {
    value: k,
    label: _Enums.EnumClientTaxCategory[k].replace(/_/g, ' ')
  };
})));
exports.clientTaxCategoryAsOptions = clientTaxCategoryAsOptions;
var docTypeAsOptions = [{
  value: undefined,
  label: '-- SELECCIONE --'
}].concat((0, _toConsumableArray2["default"])(Object.keys(_Enums.EnumIdentificationType).map(function (k) {
  return {
    value: k,
    label: _Enums.EnumIdentificationType[k].replace(/_/g, ' ')
  };
})));
exports.docTypeAsOptions = docTypeAsOptions;

var getTaxTypeLabel = function getTaxTypeLabel(taxType) {
  if (!taxType) {
    return null;
  }

  switch (taxType) {
    case _Enums.EnumTaxType.IVA_10_5:
      return '10.5%';

    case _Enums.EnumTaxType.IVA_21:
      return '21%';

    case _Enums.EnumTaxType.IVA_27:
      return '27%';

    case _Enums.EnumTaxType.RET_IIBB:
      return 'IIBB BS.AS.';

    case _Enums.EnumTaxType.IVA_0:
      return 'EXCENTO';

    case _Enums.EnumTaxType.RET_IIBB_LA_PAMPA:
      return 'IIBB LA PAMPA';

    case _Enums.EnumTaxType.PERCEPCION_IVA_3:
      return 'PERCEPCIÓN IVA 3%';

    case _Enums.EnumTaxType.AJUSTE_REDONDEO:
      return 'REDONDEO';
  }
};

exports.getTaxTypeLabel = getTaxTypeLabel;
var taxTypeAsOptions = Object.keys(_Enums.EnumTaxType).map(function (k) {
  return {
    value: k,
    label: getTaxTypeLabel(k)
  };
});
exports.taxTypeAsOptions = taxTypeAsOptions;

var getPaymentScheduleStatusLabel = function getPaymentScheduleStatusLabel(status) {
  if (!status) {
    return null;
  }

  switch (status) {
    case _Enums.EnumPaymentScheduleStatus.EXPIRED:
      return 'VENCIDO';

    case _Enums.EnumPaymentScheduleStatus.PROCESSED:
      return 'PAGADO';

    case _Enums.EnumPaymentScheduleStatus.SCHEDULED:
      return 'PLANIFICADO';
  }
};

exports.getPaymentScheduleStatusLabel = getPaymentScheduleStatusLabel;

var translateRoleLabel = function translateRoleLabel(label) {
  switch (label) {
    case _Enums.EnumRoles.ADMIN:
      return 'ADMIN';

    case _Enums.EnumRoles.ADMIN_EMPLOYEE:
      return 'EMPLEADO';

    case _Enums.EnumRoles.SELLER:
      return 'VENDEDOR';

    case _Enums.EnumRoles.WEB_CLIENT:
      return 'CLIENTE WEB';
  }
};

exports.translateRoleLabel = translateRoleLabel;

var roleOptions = function roleOptions(includeEmpty) {
  return [].concat((0, _toConsumableArray2["default"])(includeEmpty ? [{
    label: '',
    value: undefined
  }] : []), (0, _toConsumableArray2["default"])(Object.keys(_Enums.EnumRoles).map(function (u) {
    return {
      label: translateRoleLabel(_Enums.EnumRoles[u]),
      value: u
    };
  })));
};

exports.roleOptions = roleOptions;