import React, { Suspense, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PageNotFound from '../utils/common-components/PageNotFound';
import LoginComponent from '../pages/LoginComponent';
import PrivateShopRoutes from './routes.shop.private';
import PublicShopRoutes from './routes.shop';
const SiteHomePage = lazy(() => import('../pages/ecommerce/site/SiteHomePage'));
const SiteQuienesSomos = lazy(() => import('../pages/ecommerce/site/SiteWhoWeAre'));
const SiteContactPage = lazy(() => import('../pages/ecommerce/site/SiteContactPage'));
const SiteBrands = lazy(() => import('../pages/ecommerce/site/SiteBrands'));
const SiteVCard = lazy(() => import('../pages/ecommerce/site/SiteVCard'));
import PrivateRouteValidator from './PrivateRouteValidator';
import LogoutComponent from '../utils/common-components/LogoutComponent';
import { Loading } from '../utils/common-components/UIBlocker';
import { SITE_PRIVATE_BASE_PATH } from '../pages/ecommerce/public-site-utils';
import ErrorPageSite from '../utils/common-components/ErrorPageSite';

const SiteRoutes = () => {
  return (
    <Suspense fallback={<Loading loading />}>
      <Switch>
        <Route exact path="/login" component={LoginComponent} />
        <Route exact path="/logout" component={LogoutComponent} />
        <Route exact path="/error" component={ErrorPageSite} />
        <Route path="/shop/" component={PublicShopRoutes} />
        <Route exact path="/home" component={SiteHomePage} />
        <Route exact path="/whoweare" component={SiteQuienesSomos} />
        <Route exact path="/contact" component={SiteContactPage} />
        <Route exact path="/brands" component={SiteBrands} />
        <Route exact path="/vcard/:person" component={SiteVCard} />
        <Redirect exact from="/" to={`/home`} />
        <PrivateRouteValidator path={SITE_PRIVATE_BASE_PATH} component={PrivateShopRoutes} />
        <Route component={PageNotFound} />
      </Switch>
    </Suspense>
  );
};

export default SiteRoutes;

/**
 * NOTES FOR USE
 * Please, do not add any Suspense tag inside the route files, with this one in the root it works. If JIC you add one
 * add it as a root element, not inside the switch tag since it brokes the routing
 */
