import React from 'react';
import { Typography } from '@mui/material';
import { Column, Row } from 'simple-flexbox';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { WhatsApp } from '@mui/icons-material';
import {
  VERSION,
  FOOTER_PRIVACY_TERMS_URL,
  COMPANY_FULLNAME,
  COMPANY_ADDRESS,
  COMPANY_PHONE
} from '../../utils/globals';
import LogoSitio from './components/LogoSitio';
import { SITE_PRIVATE_BASE_PATH } from './public-site-utils';
import CityIcon from '../../utils/icons/city';
import LocationIcon from '../../utils/icons/location';
import { makePhoneNumberInternational } from '../../utils/makePhoneNumberInternational';
import classes from './FooterPublicSite.module.scss';

const FooterPublicSite = () => {
  const onWhatsappClick = () => {
    const message = 'Hola! Estaría interesado en obtener más información sobre los productos.';
    window.open(
      `https://wa.me/${makePhoneNumberInternational(COMPANY_PHONE)}?text=${encodeURI(message)}`
    );
  };

  return (
    <div id="footerContainer" className={cx(classes.hide, classes.container)}>
      <Row id="footerInnerContainer" className={cx(classes.innerContainer)} horizontal="center">
        <Column className={classes.contentColumn}>
          <div className={cx(classes.title)}>RIES Lubricantes</div>
          <Link to={`/home`} className={classes.footerSectionLink}>
            INICIO
          </Link>
          <Link to={`/whoweare`} className={classes.footerSectionLink}>
            NOSOTROS
          </Link>
          <Link to={`/brands`} className={classes.footerSectionLink}>
            MARCAS
          </Link>
          <Link
            to={{
              pathname: `${SITE_PRIVATE_BASE_PATH}catalog/1`,
              state: { redirectTo: `${SITE_PRIVATE_BASE_PATH}catalog/1` }
            }}
            className={classes.footerSectionLink}
          >
            CATÁLOGO
          </Link>
          <Link to={`/contact`} className={classes.footerSectionLink}>
            CONTACTO
          </Link>
        </Column>
        <Column className={classes.contentColumn}>
          <div className={cx(classes.title)} style={{ textAlign: 'center' }}>
            CONTACTO
          </div>
          <Row vertical="center" style={{ marginBottom: 10 }}>
            <LocationIcon className={classes.footerIcon} />
            <Typography className={classes.text}>{COMPANY_ADDRESS}</Typography>
          </Row>

          <Row vertical="center" style={{ marginBottom: 10 }}>
            <CityIcon className={classes.footerIcon} />
            <Typography className={classes.text}>Bahía Blanca (8000) - Pcia de Bs. As.</Typography>
          </Row>

          <Row
            vertical="center"
            style={{ marginBottom: 10, cursor: 'pointer' }}
            onClick={onWhatsappClick}
          >
            <WhatsApp className={classes.footerIcon} />
            <Typography className={classes.text}>{COMPANY_PHONE}</Typography>
          </Row>
        </Column>

        <Column className={classes.contentColumn}>
          <LogoSitio shortVersion classNameImg={cx(classes.footerlogo, classes.attention)} />
        </Column>
      </Row>
      <Typography className={classes.lastText} align={'center'}>
        {COMPANY_FULLNAME}™. Copyright {new Date().getFullYear()}. Todos los derechos reservados.
      </Typography>
      <Typography className={classes.lastText} align={'center'}>
        <a
          href={FOOTER_PRIVACY_TERMS_URL}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          Pol&iacute;tica de Privacidad
        </a>
      </Typography>
      <Typography className={classes.lastText} align={'center'}>
        by EFSOFT v{VERSION}
      </Typography>
    </div>
  );
};

export default FooterPublicSite;
