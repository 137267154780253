import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@mui/material/SvgIcon';
import { string, number, oneOfType } from 'prop-types';

let CityIcon = ({ color, width, height, ...rest }) => {
  return (
    <SvgIcon
      {...rest}
      width={width}
      height={height}
      viewBox="0 0 300.000000 300.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path
          d="M590 2130 l0 -860 -110 0 -110 0 0 175 0 175 -65 0 -65 0 0 -175 0
-175 -107 0 -108 0 3 -630 2 -630 1475 0 1475 0 0 1090 0 1090 -385 0 -384 0
-3 132 -3 131 -102 35 -103 34 0 224 0 224 -70 0 -70 0 0 -201 0 -200 -22 7
c-13 4 -45 15 -73 24 l-50 17 -3 176 -2 177 -65 0 -65 0 0 -150 c0 -114 -3
-150 -13 -150 -6 0 -228 72 -492 160 -263 88 -481 160 -482 160 -2 0 -3 -387
-3 -860z m810 453 c360 -119 661 -221 668 -226 9 -7 12 -242 12 -1113 l0
-1104 -185 0 -185 0 0 114 c0 110 1 114 23 120 39 9 114 65 139 104 100 150
46 340 -117 414 -130 58 -289 1 -356 -128 -20 -39 -24 -63 -24 -128 0 -70 4
-87 27 -128 33 -55 98 -113 144 -128 l34 -12 0 -114 0 -114 -180 0 -180 0 0
565 0 565 -250 0 -250 0 0 765 c0 605 3 765 13 765 6 0 307 -97 667 -217z
m1308 -780 l3 -113 -146 0 -145 0 0 108 c0 60 3 112 7 116 4 3 68 5 142 4
l136 -3 3 -112z m2 -353 l0 -110 -145 0 -145 0 0 110 0 110 145 0 145 0 0
-110z m0 -350 l0 -110 -145 0 -145 0 0 110 0 110 145 0 145 0 0 -110z m-2190
-180 l0 -70 -110 0 -110 0 0 70 0 70 110 0 110 0 0 -70z m430 46 c0 -13 -3
-44 -6 -70 l-7 -46 -108 0 -109 0 0 70 0 70 115 0 c114 0 115 0 115 -24z
m1760 -226 l0 -110 -145 0 -145 0 0 110 0 110 145 0 145 0 0 -110z m-2190
-105 l0 -75 -110 0 -110 0 0 75 0 75 110 0 110 0 0 -75z m425 64 c4 -6 4 -39
0 -75 l-7 -64 -109 0 -109 0 0 75 0 75 109 0 c68 0 112 -4 116 -11z m1765
-309 l0 -110 -145 0 -145 0 0 110 0 110 145 0 145 0 0 -110z m-2190 -35 l0
-75 -110 0 -110 0 0 75 0 75 110 0 110 0 0 -75z m425 44 c4 -17 4 -51 0 -75
l-6 -44 -110 0 -109 0 0 75 0 75 109 0 109 0 7 -31z"
        />
        <path d="M880 2335 l0 -65 205 0 205 0 0 65 0 65 -205 0 -205 0 0 -65z" />
        <path d="M880 2053 l0 -68 524 0 524 0 -1 67 -2 68 -522 0 -523 0 0 -67z" />
        <path d="M880 1765 l0 -65 275 0 275 0 0 65 0 65 -275 0 -275 0 0 -65z" />
        <path d="M1580 1765 l0 -65 173 0 172 0 1 65 2 65 -174 0 -174 0 0 -65z" />
        <path d="M880 1485 l0 -65 523 0 522 0 0 65 1 65 -523 0 -523 0 0 -65z" />
        <path
          d="M1370 1205 l0 -65 275 0 275 0 0 28 c0 15 3 44 6 65 l7 37 -282 0
-281 0 0 -65z"
        />
      </g>
    </SvgIcon>
  );
};

CityIcon = pure(CityIcon);

CityIcon.displayName = 'City';

CityIcon.muiName = 'SvgIcon';

CityIcon.defaultProps = {
  color: '#333F4D',
  width: '300.000000pt',
  height: '300.000000pt'
};

CityIcon.propTypes = {
  viewBox: string,
  width: oneOfType(string, number),
  height: oneOfType(string, number),
  color: string
};

export default CityIcon;
