"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateProductOptimisticResponse = exports.createProductOptimisticResponse = exports.createSellOptimisticResponse = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var createSellOptimisticResponse = function createSellOptimisticResponse(sellPayload) {
  return {
    createSell: {
      __typename: 'createSellPayload',
      sell: _objectSpread(_objectSpread({}, sellPayload), {}, {
        items: (0, _toConsumableArray2["default"])(sellPayload.items).map(function (i) {
          return _objectSpread(_objectSpread({}, i), {}, {
            __typename: 'SellItem'
          });
        }),
        __typename: 'Sell'
      })
    }
  };
};

exports.createSellOptimisticResponse = createSellOptimisticResponse;

var createProductOptimisticResponse = function createProductOptimisticResponse(payload) {
  return {
    createProduct: {
      __typename: 'createProductPayload',
      product: _objectSpread(_objectSpread({}, payload), {}, {
        __typename: 'Product'
      })
    }
  };
};

exports.createProductOptimisticResponse = createProductOptimisticResponse;

var updateProductOptimisticResponse = function updateProductOptimisticResponse(payload) {
  return {
    createProduct: {
      __typename: 'updateProductPayload',
      product: _objectSpread(_objectSpread({}, payload), {}, {
        __typename: 'Product'
      })
    }
  };
};

exports.updateProductOptimisticResponse = updateProductOptimisticResponse;