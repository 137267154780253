import decode from 'unescape';
import Quagga from 'quagga';
import * as localStorageService from '../services/localStorageService';
import { browserHistory } from './globals';

export const SQL_FORMAT = 'YYYY-MM-DD HH:mm';
export const SQL_FORMAT_NO_TIME = 'YYYY-MM-DD';

export function setUrlToRedirectAfterLogin(url) {
  localStorageService.setItem('redirectToAfterLogin', url);
}

export function getUrlToRedirectAfterLogin() {
  return localStorageService.getItem('redirectToAfterLogin');
}

export function setCurrentUrlToRedirectAfterLogin() {
  if (!['/login', '/error'].some((r) => browserHistory?.location?.pathname?.indexOf(r) !== -1)) {
    const url = browserHistory.location.pathname + location.search;
    setUrlToRedirectAfterLogin(url);
  }
}

export const createMarkup = (htmlString) => ({ __html: decode(htmlString) });

export const isDark = (theme) => theme?.palette?.mode === 'dark' || false;

export const decodeImgAsync = async (src, readers) => {
  return new Promise((resolve, reject) => {
    Quagga.decodeSingle(
      {
        src,
        numOfWorkers: 0, // Needs to be 0 when used within node
        inputStream: {
          size: 800 // restrict input-size to be 800px in width (long-side)
        },
        decoder: {
          readers // List of active readers
        },
        debug: {
          drawBoundingBox: true,
          drawScanline: true,
          showPattern: true
        },
        locator: {
          halfSample: true,
          patchSize: 'xlarge'
        }
      },
      function (result) {
        if (result && result.codeResult) {
          console.log(`ESCANEADO: ${result.codeResult.code} -  ${result.codeResult.format}`);
          resolve(result.codeResult.code);
        } else {
          reject('not detected');
        }
      }
    );
  });
};

export const decodeImgBar = async (src, readers) => {
  try {
    const defaultReaders = ['ean_reader', 'ean_8_reader'];
    const result = await decodeImgAsync(src, readers || defaultReaders);
    return result;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const isUserMediaSupported = () =>
  navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function';

export const getClientFirstPhone = (client) => {
  if (!client || !client.phones || client.phones.length <= 0) return '';
  const phone = client.phones[0];
  if (phone.areaCode) {
    return `(${phone.areaCode}) ${phone.number}`;
  }
  return phone.number;
};

export const sanitizeEnumValue = (value, defaultString) => {
  if (!value) return defaultString || null;
  return value.replace(/_/g, ' ');
};

export const forceDownloadFile = (url, filename) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  // 3. Append to html page
  document.body.appendChild(link);
  // 4. Force download
  link.click();
  // 5. Clean up and remove the link
  link.parentNode.removeChild(link);
};

export const getFullCityString = (city) =>
  city ? `${city.name}, ${!city.zipcode ? city.zipcode : ''} ${city.state}` : '';

export const toGloblalId = (type, id) => Buffer.from(`${type}:${id || ''}`).toString('base64');

// Detects if device is in standalone mode
export const isInStandaloneMode = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

  if (document.referrer.startsWith('android-app://') || navigator.standalone || isStandalone) {
    return true;
  }

  return false;
};

export const fileUrlToBlob = async (fileUrl) => {
  const res = await fetch(fileUrl);
  return res.blob();
};

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

// USO ESTO Q PERMITE PAYLOAD NULL
export const fromGlobalId = (payload) => {
  if (!payload) return '';
  const [type, id] = atob(payload.toString()).split(':');
  return {
    type,
    id
  };
};

// esto fue lo unico que anduvo en prod
export const setPageSize = (cssPageSize) => {
  const style = document.createElement('style');
  style.innerHTML = `@page {size: ${cssPageSize}}`;
  style.id = 'page-orientation';
  document.head.appendChild(style);
};
