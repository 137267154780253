import React from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';

const EFValidatorForm = ({ children, onSubmit, onError }) => {
  const handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);
    return onSubmit(formProps);
  };
  return (
    <ValidatorForm onSubmit={handleSubmit} onError={onError || (() => console.log)}>
      {children}
    </ValidatorForm>
  );
};

EFValidatorForm.propTypes = {
  children: PropTypes.any,
  onError: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
};

export default EFValidatorForm;
