// import React from 'react';
import { object, func } from 'prop-types';
import { withStyles } from '@mui/styles';
import * as media from '../../utils/styles/media-queries';
import * as colors from '../../utils/styles/colors';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { PRIVATE_BASE_PATH } from '../../utils/globals';
// import { Column } from 'simple-flexbox';
// import { Link } from 'react-router-dom';

const styles = () => ({
  root: {
    ...media.hideOnPrint
  },
  offlineBanner: {
    height: 30,
    width: '100%',
    backgroundColor: colors.red,
    padding: 8,
    color: colors.white,
    marginBottom: 10
  }
});

const BreadCrumb = () => {
  // const { classes } = props;
  // const offline = useSelector((state) => state.offline);
  const notHasBread = [`${PRIVATE_BASE_PATH}dashboard`, 'print', 'login', 'paymentinvoice'].some(
    (route) => {
      return location.pathname.indexOf(route) !== -1;
    }
  );
  if (notHasBread) {
    return null;
  }
  return null;
};

BreadCrumb.propTypes = {
  classes: object,
  onBack: func
};

export default withStyles(styles)(BreadCrumb);

/* */
