// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

// import { precacheAndRoute } from 'workbox-precaching';

// precacheAndRoute(self.__WB_MANIFEST || []);

export function register() {
  // Register the service worker

  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = '/service-worker.js';
      navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
          console.log('SW Registered successfully');
          // registration.onupdatefound = () => {
          //   const serviceWorker = registration.installing;
          //   serviceWorker.skipWaiting();

          //   serviceWorker.onstatechange = () => {
          //     if (serviceWorker.state === 'installed') {
          //       if (navigator.serviceWorker.controller) {
          //         // At this point, the old content will have been purged and
          //         // the fresh content will have been added to the cache.
          //         // It's the perfect time to display a "New content is
          //         // available; please refresh." message in your web app.
          //         console.log('New content is available; please refresh.');
          //       } else {
          //         // At this point, everything has been precached.
          //         // It's the perfect time to display a
          //         // "Content is cached for offline use." message.
          //         console.log('Content is cached for offline use.');
          //       }
          //     } else if (serviceWorker.active) {
          //       // serviceWorker.state is activated or activating
          //       serviceWorker.clientsClaim();
          //     }
          //   };
          // };
          let serviceWorker;
          if (registration.installing) {
            serviceWorker = registration.installing;
            console.log('ServiceWorker is in state --> installing');
          } else if (registration.waiting) {
            serviceWorker = registration.waiting;
            console.log('ServiceWorker is in state --> waiting');
          } else if (registration.active) {
            serviceWorker = registration.active;
            console.log('ServiceWorker is in state --> active');
          }
          if (serviceWorker) {
            serviceWorker.addEventListener('install', () => {
              console.log('ServiceWorker is in state --> installing');
              serviceWorker.skipWaiting();
            });
            self.addEventListener('activate', () => {
              console.log('ServiceWorker is in state --> active');
              self.clients.claim();
            });
            serviceWorker.addEventListener('statechange', () => {
              if (serviceWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  // At this point, the old content will have been purged and
                  // the fresh content will have been added to the cache.
                  // It's the perfect time to display a "New content is
                  // available; please refresh." message in your web app.
                  console.log('New content is available; please refresh.');
                } else {
                  // At this point, everything has been precached.
                  // It's the perfect time to display a
                  // "Content is cached for offline use." message.
                  console.log('Content is cached for offline use.');
                }
              }
            });
          }
        })
        .catch((error) => {
          console.error('Error during service worker registration:', error);
        });
    });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration
          .unregister()
          .then(() => {
            return self.clients.matchAll();
          })
          .then((clients) => {
            clients.forEach((client) => {
              if (client.url && 'navigate' in client) {
                client.navigate(client.url);
              }
            });
          });
      }
    });
  }
}
