import React, { useEffect } from 'react';
import { func } from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import { isUserLoggedIn, lightLogout } from '../services/authService';

const PrivateRouteValidator = ({ component: Component, ...rest }) => {
  useEffect(() => {
    if (!isUserLoggedIn()) {
      lightLogout(true, true);
    }
  }, []);

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

PrivateRouteValidator.propTypes = {
  component: func
};

export default withRouter(PrivateRouteValidator);
