"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.computeInvoiceTotals = exports.updateItemTotal = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _sharedUtilities = require("../shared-utilities");

var _Enums = require("../enums/Enums");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * RETURNS item.total as number
 * @param {} item
 */
var updateItemTotal = function updateItemTotal(item) {
  var it = _objectSpread({}, item); // if no discount just 1 to multiply without effect


  var discount = it.discount ? 1 - parseFloat(it.discount) / 100 : 1;
  var price = it.unitPrice ? (0, _sharedUtilities.din)(it.unitPrice) : _sharedUtilities.DINEROJS_ZERO;
  var quantity = it.quantity || 0;
  it.total = (0, _sharedUtilities.roundDin)(price.multiply(discount).multiply(quantity));
  return it;
};
/**
 * Return {
 *  total as DineroJS
 *  totalDiscounted as DineroJS
 *  totalNet
 *  taxes
 * }
 * @param {*} items
 * @param {*} taxes
 */


exports.updateItemTotal = updateItemTotal;

var computeInvoiceTotals = function computeInvoiceTotals(items) {
  var taxes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var iva21 = _sharedUtilities.DINEROJS_ZERO;
  var iva27 = _sharedUtilities.DINEROJS_ZERO;
  var iva105 = _sharedUtilities.DINEROJS_ZERO;
  var totalItems = _sharedUtilities.DINEROJS_ZERO;
  var totalDiscounted = _sharedUtilities.DINEROJS_ZERO;
  var editableTaxes = _sharedUtilities.DINEROJS_ZERO;
  /*
  Como los IVA son calculados automaticamente. filtro los taxes que puede agregar el usuario y dejo esos
  los demas vuelven a agregarse al array con el valor actualizado
  */

  var newTaxes = taxes && taxes.filter(function (t) {
    if (t.editable && t.amount) {
      editableTaxes = editableTaxes.add((0, _sharedUtilities.din)(t.amount));
    }

    return t.editable;
  }) || [];

  if (items) {
    items.forEach(function (i) {
      var totalDin = (0, _sharedUtilities.din)(i.total || 0);
      var iva = i.taxPercentage ? (0, _sharedUtilities.getIvaValue)(i.taxPercentage) ? parseFloat((0, _sharedUtilities.getIvaValue)(i.taxPercentage)) / 100 : 1 : 1;
      totalItems = totalItems.add(totalDin);
      var disc = i.unitPrice && i.quantity && i.total ? (0, _sharedUtilities.din)(i.unitPrice).multiply(i.quantity).subtract(totalDin) : _sharedUtilities.DINEROJS_ZERO;
      totalDiscounted = totalDiscounted.add(disc);

      switch (i.taxPercentage) {
        case _Enums.EnumTaxType.IVA_10_5:
          iva105 = iva105.add(totalDin.multiply(iva));
          break;

        case _Enums.EnumTaxType.IVA_21:
          iva21 = iva21.add(totalDin.multiply(iva));
          break;

        case _Enums.EnumTaxType.IVA_27:
          iva27 = iva27.add(totalDin.multiply(iva));
          break;
      }
    });
    newTaxes.push({
      taxType: _Enums.EnumTaxType.IVA_10_5,
      amount: (0, _sharedUtilities.roundDin)(iva105)
    });
    newTaxes.push({
      taxType: _Enums.EnumTaxType.IVA_21,
      amount: (0, _sharedUtilities.roundDin)(iva21)
    });
    newTaxes.push({
      taxType: _Enums.EnumTaxType.IVA_27,
      amount: (0, _sharedUtilities.roundDin)(iva27)
    });
  }

  var total = totalItems.add(iva105).add(iva21).add(iva27).add(editableTaxes);
  return {
    total: total,
    totalNet: totalItems,
    totalDiscounted: totalDiscounted,
    taxes: newTaxes
  };
};

exports.computeInvoiceTotals = computeInvoiceTotals;