import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { IS_ADMIN, IS_SELLER } from '../utils/globals';
const AdminRoutes = lazy(() => import('./routes.admin'));
const SellerRoutes = lazy(() => import('./routes.seller'));
const UserAgenda = lazy(() => import('../pages/general/UserAgenda'));
const UserTasks = lazy(() => import('../pages/general/UserTasks'));

const AppRoutes = (props) => {
  const me = props.match.url;

  return (
    <>
      {IS_ADMIN && <AdminRoutes {...props} />}
      {IS_SELLER && <SellerRoutes {...props} />}
      <Route exact path={`${me}useragenda`} component={UserAgenda} />
      <Route exact path={`${me}usertasks`} component={UserTasks} />
    </>
  );
};

AppRoutes.propTypes = {
  match: PropTypes.object
};

export default AppRoutes;
