import { useLottie } from 'lottie-react';
import PropTypes from 'prop-types';
import unlockAnimationData from './un-lock-animation.json';

const UnlockAnimation = ({ loop, autoplay, style }) => {
  const options = {
    animationData: unlockAnimationData,
    loop,
    autoplay
  };

  const { View } = useLottie(options, style);
  return View;
};

UnlockAnimation.propTypes = {
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  style: PropTypes.object
};

UnlockAnimation.defaultProps = {
  loop: true,
  autoplay: true
};

export default UnlockAnimation;
