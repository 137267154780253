import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@mui/material/SvgIcon';
import { string, number, oneOfType } from 'prop-types';

let LocationIcon = ({ color, width, height, ...rest }) => {
  return (
    <SvgIcon
      {...rest}
      width={width}
      height={height}
      viewBox="0 0 300.000000 300.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path
          d="M1350 2984 c-70 -12 -193 -52 -254 -82 -236 -115 -397 -300 -488
-562 -32 -93 -32 -98 -33 -275 0 -162 2 -188 24 -255 19 -63 184 -414 201
-430 3 -3 64 -122 135 -264 72 -143 133 -265 138 -270 4 -6 43 -81 86 -166
210 -416 336 -660 341 -660 5 0 76 138 350 675 59 116 156 304 215 420 370
719 359 688 359 945 1 184 0 186 -32 280 -92 265 -256 452 -494 564 -134 64
-196 77 -368 81 -85 2 -166 2 -180 -1z m294 -375 c113 -22 268 -136 338 -249
46 -74 66 -131 77 -220 10 -76 -1 -225 -19 -258 -5 -9 -20 -42 -33 -72 -13
-30 -38 -71 -55 -90 -18 -19 -32 -38 -32 -42 0 -14 -122 -100 -182 -128 -35
-16 -102 -36 -150 -44 -79 -14 -97 -14 -175 0 -49 8 -116 28 -151 44 -60 28
-182 114 -182 128 0 4 -14 23 -32 42 -17 19 -42 60 -55 90 -13 30 -28 63 -33
72 -18 33 -29 182 -19 258 16 134 69 236 172 333 140 132 318 177 531 136z"
        />
      </g>
    </SvgIcon>
  );
};

LocationIcon = pure(LocationIcon);

LocationIcon.displayName = 'Location';

LocationIcon.muiName = 'SvgIcon';

LocationIcon.defaultProps = {
  color: '#333F4D',
  width: '300.000000pt',
  height: '300.000000pt'
};

LocationIcon.propTypes = {
  viewBox: string,
  width: oneOfType(string, number),
  height: oneOfType(string, number),
  color: string
};

export default LocationIcon;
