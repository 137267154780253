const ROUTES_WITHOUT_HEADER = ['/vcard/'];
// export const PUBLIC_SITE_GA_TRACKING_ID = 'UA-178018593-1';
export const PUBLIC_SITE_GA_TRACKING_ID = 'G-DMHME0WXL8'; // measurement id not client id
export const PUBLIC_SITE_GA_TRACKING_ID_TEST_MODE = 'G-2EY1Q6Q0K7'; // measurement id not client id
export const PUBLIC_SITE_FA_PIXEL_TRACKING_ID = 'goeshere';
export const SITE_PRIVATE_BASE_PATH = '/web/private/';

export const hasSiteHeader = (path) => ROUTES_WITHOUT_HEADER.every((r) => !path.includes(r));

export const isIntroPage = (path) => path == '/site';
