import React, { useEffect, useState } from 'react';
import { MenuItem, Menu, Avatar, ListItemIcon, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Row } from 'simple-flexbox';
import Skeleton from '@mui/material/Skeleton';
import { AccountCircle, ExitToApp, AccountCircleTwoTone } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import * as media from '../styles/media-queries';

const styles = (theme) => ({
  ...media.hideSmall,
  ...media.hideXSmall,
  ...media.hideLarge,
  hideOnPrint: { ...media.hideOnPrint },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  profileAvatar: {
    width: 50,
    height: 50,
    marginRight: 10,
    ...media.xSmallScreen({
      width: 40,
      height: 40,
      marginRight: 0
    })
  }
});

const ProfileMenu = ({ classes, user, itemClicked, redirectToProfile, history }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setAnchorEl(null);
  }, [itemClicked]);

  useEffect(() => {
    // hook cleanup, the new componentWillUnmount
    return () => {
      setAnchorEl(null);
    };
  }, []);

  const handleRedirectToProfile = () => {
    setAnchorEl(null);
    redirectToProfile();
  };

  const redirectToLogout = () => {
    setAnchorEl(null);
    history.push(`/logout`);
  };

  return (
    <>
      {/* profile menu begins */}
      <Row vertical="center" onClick={(event) => setAnchorEl(event.currentTarget)}>
        {user ? (
          <React.Fragment>
            {user.profilePhotoUrl ? (
              <Avatar alt="pic" src={user.profilePhotoUrl} className={classes.profileAvatar} />
            ) : (
              <AccountCircleTwoTone className={classes.profileAvatar} />
            )}
            <Typography className={classes.hideXSmall} variant="subtitle1">
              {`Hola ${user.firstName}!`}
            </Typography>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Skeleton variant="circle" className={classes.profileAvatar} />
            {!media.isXSmallScreen() && <Skeleton variant="text" width={100} />}
          </React.Fragment>
        )}
      </Row>
      <Menu
        disableAutoFocusItem
        classes={{ paper: classes.hideOnPrint }}
        id="profile-menu-toolbar"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem className={classes.menuItem} onClick={handleRedirectToProfile}>
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={redirectToLogout} id="logout">
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      {/* profile menu ends */}
    </>
  );
};

ProfileMenu.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  history: PropTypes.object,
  itemClicked: PropTypes.func,
  redirectToProfile: PropTypes.func
};

export default compose(withRouter, withStyles(styles, { withTheme: true }))(ProfileMenu);
