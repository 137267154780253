import React from 'react';
import { object, bool } from 'prop-types';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import classnames from 'classnames';
import MainRoutes from './routes/routes.main';
import BarsComponent from './utils/common-components/BarsComponent';
import * as colors from './utils/styles/colors';
import * as media from './utils/styles/media-queries';
import { ENABLE_PWA } from './utils/globals';
import BreadCrumb from './utils/common-components/BreadCrumb';
import Footer from './utils/common-components/Footer';
import * as serviceWorker from './pwa/service-worker';
import customValidationRules from './utils/common-components/forms/CustomValidations';

const mapStateToProps = (state) => {
  return { opesi: state.opesi };
};

const styles = () => ({
  outerContainer: {
    backgroundColor: '#e6edf5'
  },
  opesiBkg: {
    backgroundColor: colors.lightGray,
    border: '6px solid red'
  },
  innerContainer: {
    paddingLeft: 60,
    minHeight: '80vh',
    ...media.xlScreen({
      paddingLeft: 35
    }),
    ...media.xSmallScreen({
      paddingLeft: 0,
      minHeight: '57vh'
    })
  }
});

const MainApplication = ({ classes, location, opesi }) => {
  if (ENABLE_PWA) {
    serviceWorker.register();
    // if ('serviceWorker' in navigator) {
    //   await navigator.serviceWorker.register('/service-worker.js', {
    //     scope: '.'
    //   });
    // console.log('SW Registered successfully');
    // }
  } else {
    // serviceWorker.unregister();
  }

  customValidationRules();

  const routesWithBkg = [
    `/admin/sells/create`,
    `/admin/sells/quoterequests/create`,
    '/admin/dashboard',
    '/admin/sells/dashboard',
    '/admin/sells/stats',
    '/admin/sells/index',
    '/admin/clients/index/',
    '/admin/clients/view/',
    '/admin/clients/print-funds-transfer',
    '/admin/sells/commissions/compute',
    '/seller/sells/commissions/compute'
  ];

  const routesWithoutFooter = [`/login`];

  const hasBkg = opesi && routesWithBkg.some((route) => location.pathname.includes(route));
  const notHasFooter = routesWithoutFooter.some((route) => location.pathname.includes(route));

  return (
    <div id="outer-container" className={classes.outerContainer}>
      <BarsComponent location={location} />
      <div id="page-wrap" className={classnames({ [classes.opesiBkg]: hasBkg })}>
        <div id="inner-container" className={classes.innerContainer}>
          <BreadCrumb />
          <MainRoutes />
        </div>
        {!notHasFooter && <Footer />}
      </div>
    </div>
  );
};

MainApplication.propTypes = {
  location: object,
  classes: object,
  opesi: bool,
  history: object
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles, { withTheme: true })
)(MainApplication);
