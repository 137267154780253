"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EnumAfipConcept = exports.EnumAfipIvaType = exports.EnumAfipInvoiceType = exports.EnumRoles = exports.EnumContacMessageSource = exports.EnumContacMessageHowHeard = exports.EnumClientStatus = exports.EnumPaymentScheduleStatus = exports.EnumTaxType = exports.EnumNotificationType = exports.EnumNotificationTypeCore = exports.EnumSellRequestStatus = exports.EnumIdentificationType = exports.EnumClientTaxCategory = exports.EnumSellDiscountType = exports.EnumFundsTransferType = exports.EnumSellType = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var EnumSellType = {
  SELL: 'SELL',
  SELL2: 'SELL2',
  QUOTE_REQUEST: 'QUOTE_REQUEST',
  QUOTE_REQUEST2: 'QUOTE_REQUEST2'
};
exports.EnumSellType = EnumSellType;
var EnumFundsTransferType = {
  SELL: 'SELL',
  SELL2: 'SELL2',
  QUOTE_REQUEST: 'QUOTE_REQUEST',
  QUOTE_REQUEST2: 'QUOTE_REQUEST2',
  CLIENT_PAYMENT: 'CLIENT_PAYMENT',
  INTERNAL_TRANSFER: 'INTERNAL_TRANSFER',
  SUPPLIER_PAYMENT: 'SUPPLIER_PAYMENT',
  CASH_INJECTION: 'CASH_INJECTION',
  CASH_EXTRACTION: 'CASH_EXTRACTION',
  EXPENSES: 'EXPENSES',
  SOFTWARE_DEVELOPMENT: 'SOFTWARE_DEVELOPMENT',
  IT_SERVICES: 'IT_SERVICES',
  MAINTENANCE: 'MAINTENANCE',
  BILLS: 'BILLS',
  SALARY: 'SALARY',
  ALLOWANCES: 'ALLOWANCES',
  SALE_COMISSIONS: 'SALE_COMISSIONS',
  ACCOUNT_AMORTIZATION: 'ACCOUNT_AMORTIZATION',
  CN_APPLIED: 'CN_APPLIED',
  FUEL_EXPENSE: 'FUEL_EXPENSE'
};
exports.EnumFundsTransferType = EnumFundsTransferType;
var EnumSellDiscountType = {
  CREDIT_NOTE: 'CREDIT_NOTE',
  CLIENT_DISCOUNT: 'CLIENT_DISCOUNT',
  CUPON: 'CUPON'
};
exports.EnumSellDiscountType = EnumSellDiscountType;
var EnumClientTaxCategory = {
  CONSUMIDOR_FINAL: 'CONSUMIDOR_FINAL',
  EVENTUAL: 'EVENTUAL',
  EVENTUAL_SOCIAL: 'EVENTUAL_SOCIAL',
  MONOTRIBUTO: 'MONOTRIBUTO',
  MONOTRIBUTO_SOCIAL: 'MONOTRIBUTO_SOCIAL',
  NO_RESPONSABLE: 'NO_RESPONSABLE',
  NO_CATEGORIZADO: 'NO_CATEGORIZADO',
  RESPONSABLE_EXCENTO: 'RESPONSABLE_EXCENTO',
  RESPONSABLE_INSCRIPTO: 'RESPONSABLE_INSCRIPTO'
};
exports.EnumClientTaxCategory = EnumClientTaxCategory;
var EnumIdentificationType = {
  CDI: 'CDI',
  LE: 'LE',
  LC: 'LC',
  CI_EXTRANJERA: 'CI_EXTRANJERA',
  EN_TRAMITE: 'EN_TRAMITE',
  ACTA_NACIMIENTO: 'ACTA_NACIMIENTO',
  CI_BSAS_RNP: 'CI_BSAS_RNP',
  DNI: 'DNI',
  PASAPORTE: 'PASAPORTE',
  CI_POLICIA_FEDERAL: 'CI_POLICIA_FEDERAL'
};
exports.EnumIdentificationType = EnumIdentificationType;
var EnumSellRequestStatus = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  CANCELED: 'CANCELED',
  SOLD: 'SOLD'
};
exports.EnumSellRequestStatus = EnumSellRequestStatus;
var EnumNotificationTypeCore = {
  CHANGELOG: 'CHANGELOG'
};
exports.EnumNotificationTypeCore = EnumNotificationTypeCore;

var EnumNotificationType = _objectSpread({
  NEW_SELL: 'NEW_SELL',
  NEW_SELL_REQUEST: 'NEW_SELL_REQUEST',
  SELL_REQUEST_APPROVED: 'SELL_REQUEST_APPROVED',
  SELL_REQUEST_PROCESSED: 'SELL_REQUEST_PROCESSED',
  SELL_REQUEST_EXPIRED: 'SELL_REQUEST_EXPIRED',
  SELL_REQUEST_ABOUT_EXPIRE: 'SELL_REQUEST_ABOUT_EXPIRE',
  SELL_REQUEST_CANCELED: 'SELL_REQUEST_CANCELED',
  NEW_CLIENT_PAYMENT: 'NEW_CLIENT_PAYMENT',
  CLIENT_PENDING_APPROVAL: 'CLIENT_PENDING_APPROVAL',
  NEW_CONTACT_MESSAGE: 'NEW_CONTACT_MESSAGE',
  AGENDA_ITEM: 'AGENDA_ITEM'
}, EnumNotificationTypeCore);

exports.EnumNotificationType = EnumNotificationType;
var EnumTaxType = {
  RET_IIBB: 'RET_IIBB',
  RET_IIBB_LA_PAMPA: 'RET_IIBB_LA_PAMPA',
  PERCEPCION_IVA_3: 'PERCEPCION_IVA_3',
  AJUSTE_REDONDEO: 'AJUSTE_REDONDEO',
  IVA_10_5: 'IVA_10_5',
  IVA_21: 'IVA_21',
  IVA_27: 'IVA_27',
  IVA_0: 'IVA_0'
};
exports.EnumTaxType = EnumTaxType;
var EnumPaymentScheduleStatus = {
  EXPIRED: 'EXPIRED',
  SCHEDULED: 'SCHEDULED',
  PROCESSED: 'PROCESSED'
};
exports.EnumPaymentScheduleStatus = EnumPaymentScheduleStatus;
var EnumClientStatus = {
  BANNED: 'BANNED',
  REJECTED: 'REJECTED',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  ACTIVE: 'ACTIVE',
  DEACTIVATED: 'DEACTIVATED',
  LEAD: 'LEAD'
};
exports.EnumClientStatus = EnumClientStatus;
var EnumContacMessageHowHeard = {
  AMIGO: 'AMIGO',
  BUSCADORES: 'BUSCADORES',
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  PUBLICIDAD: 'PUBLICIDAD',
  VISITA_VENDEDOR: 'VISITA_VENDEDOR',
  YA_SOY_CLIENTE: 'YA_SOY_CLIENTE'
};
exports.EnumContacMessageHowHeard = EnumContacMessageHowHeard;
var EnumContacMessageSource = {
  CONTACT_FORM: 'CONTACT_FORM',
  CHAT_BOT: 'CHAT_BOT'
};
exports.EnumContacMessageSource = EnumContacMessageSource;
var EnumRoles = {
  ADMIN: 'ADMIN',
  SELLER: 'SELLER',
  WEB_CLIENT: 'WEB_CLIENT',
  ADMIN_EMPLOYEE: 'ADMIN_EMPLOYEE'
};
exports.EnumRoles = EnumRoles;
var EnumAfipInvoiceType = {
  FACTURA_A: 'FACTURA_A',
  FACTURA_B: 'FACTURA_B',
  FACTURA_C: 'FACTURA_C',
  NOTA_DEBITO_A: 'NOTA_DEBITO_A',
  NOTA_DEBITO_B: 'NOTA_DEBITO_B',
  NOTA_DEBITO_C: 'NOTA_DEBITO_C',
  NOTA_CREDITO_A: 'NOTA_CREDITO_A',
  NOTA_CREDITO_B: 'NOTA_CREDITO_B',
  NOTA_CREDITO_C: 'NOTA_CREDITO_C'
};
exports.EnumAfipInvoiceType = EnumAfipInvoiceType;
var EnumAfipIvaType = {
  IVA_2_5: 'IVA_2_5',
  IVA_5: 'IVA_5',
  IVA_10_5: 'IVA_10_5',
  IVA_21: 'IVA_21',
  IVA_27: 'IVA_27',
  IVA_0: 'IVA_0'
};
exports.EnumAfipIvaType = EnumAfipIvaType;
var EnumAfipConcept = {
  PRODUCTOS: 'PRODUCTOS',
  SERVICIOS: 'SERVICIOS',
  PRODUCTOS_Y_SERVICIOS: 'PRODUCTOS_Y_SERVICIOS'
};
exports.EnumAfipConcept = EnumAfipConcept;