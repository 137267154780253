import { sharedGlobals, EnumSellType } from '@eeff/shared';
import getSellsGQL from './queries/sales/sellsListQuery.graphql';

const getVariablesForSells = (operationName) => {
  switch (operationName) {
    case 'createSell':
      return {
        page: 0,
        limit: sharedGlobals.SALES_PAGE_LIMIT,
        filter: null,
        types: [EnumSellType.SELL]
      };

    case 'createQuoteRequest2':
      return {
        page: 0,
        limit: sharedGlobals.SALES_PAGE_LIMIT,
        filter: null,
        types: [EnumSellType.SELL, EnumSellType.SELL2, EnumSellType.QUOTE_REQUEST2]
      };

    case 'createQuoteRequest':
      return {
        page: 0,
        limit: sharedGlobals.SALES_PAGE_LIMIT,
        filter: null,
        types: [EnumSellType.QUOTE_REQUEST]
      };
  }
};

const updateCreateSellCache = (cache, { data: { createSell } }, opName) => {
  const variables = getVariablesForSells(opName);
  let cachedData;
  try {
    cachedData = cache.readQuery({
      query: getSellsGQL,
      variables
    });
  } catch (e) {
    console.log(`NO CACHED QUERY FOUND UPDATE FN ${opName}`);
  }
  if (!cachedData) return;
  cachedData.viewer.sales.list = [createSell.sell, ...cachedData.salesData.list];
  cache.writeQuery({ query: getSellsGQL, variables, data: cachedData });

  // update products cache
};

const updateCreateQuoteRequestCache = (cache, { data: { createSell } }, opName) => {
  const variables = getVariablesForSells(opName);
  let cachedData;
  try {
    cachedData = cache.readQuery({
      query: getSellsGQL,
      variables
    });
  } catch (e) {
    console.log(`NO CACHED QUERY FOUND UPDATE FN ${opName}`);
  }
  if (!cachedData) return;
  cachedData.viewer.quoteRequests.edges = [
    { node: createSell.sell, __typename: 'QuoteRequestViewerConnectionEdge' },
    ...cachedData.viewer.quoteRequests.edges
  ];
  cache.writeQuery({ query: getSellsGQL, variables, data: cachedData });

  // update products cache
};
// TODO FIX
// const updateCreateProduct = (cache, { data: { createProduct } }) => {
const updateCreateProduct = () => {
  // const variables = {
  //   page: 0,
  //   limit: DEFAULT_PAGE_LIMIT,
  //   filter: null,
  //   onlyActives: false
  // };
  // let cachedData;
  // try {
  //   cachedData = cache.readQuery({
  //     query: getProductsGQL,
  //     variables
  //   });
  // } catch (e) {
  //   console.log(`NO CACHED QUERY FOUND UPDATE FN CREATE PRODUCT`);
  // }
  // if (!cachedData) return;
  // cachedData.productsData.products.list = [
  //   { ...createProduct.product, __typename: 'Products' },
  //   ...cachedData.viewer.products.list
  // ];
  // cache.writeQuery({ query: getProductsGQL, variables, data: cachedData });
  // update products cache
};

export const updateFunctions = {
  createSell: (cache, data) => updateCreateSellCache(cache, data, 'createSell'),
  createQuoteRequest2: (cache, data) =>
    updateCreateQuoteRequestCache(cache, data, 'createQuoteRequest2'),
  createQuoteRequest: (cache, data) =>
    updateCreateQuoteRequestCache(cache, data, 'createQuoteRequest'),
  createSell2: console.log,
  createProduct: updateCreateProduct
};
