import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@mui/material/SvgIcon';
import { string, number, oneOfType } from 'prop-types';

let FacebookIcon = ({ color, width, height, ...rest }) => {
  return (
    <SvgIcon
      {...rest}
      width={width}
      height={height}
      viewBox="0 0 300.000000 300.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path
          d="M1205 2966 c-307 -65 -540 -190 -761 -411 -224 -224 -353 -467 -414
-782 -29 -151 -29 -395 0 -546 61 -316 189 -558 414 -782 225 -224 470 -355
781 -415 149 -28 401 -28 550 0 311 60 556 191 781 415 225 224 353 466 414
782 29 151 29 395 0 546 -61 316 -189 558 -414 782 -225 224 -470 355 -781
415 -155 29 -417 28 -570 -4z m532 -172 c453 -78 839 -404 1006 -849 59 -158
72 -234 72 -445 0 -182 -2 -202 -28 -305 -68 -261 -190 -471 -378 -650 -222
-212 -502 -335 -821 -361 l-88 -7 0 572 0 571 145 0 145 0 0 31 c0 18 9 84 19
148 11 64 21 131 23 149 l5 32 -170 0 -169 0 3 143 c6 220 31 247 227 247
l112 0 0 181 0 181 -187 -4 c-156 -4 -199 -9 -255 -27 -120 -39 -203 -122
-238 -239 -28 -95 -28 -97 -29 -299 l-1 -183 -110 0 -110 0 0 -180 0 -180 110
0 110 0 0 -535 c0 -447 -2 -535 -14 -535 -25 0 -191 71 -261 112 -228 134
-408 324 -525 552 -87 172 -129 334 -137 534 -16 368 121 717 382 977 231 230
502 355 840 389 63 6 232 -4 322 -20z"
        />
      </g>
    </SvgIcon>
  );
};

FacebookIcon = pure(FacebookIcon);

FacebookIcon.displayName = 'Facebook';

FacebookIcon.muiName = 'SvgIcon';

FacebookIcon.defaultProps = {
  color: '#333F4D',
  width: '300.000000pt',
  height: '300.000000pt'
};

FacebookIcon.propTypes = {
  viewBox: string,
  width: oneOfType(string, number),
  height: oneOfType(string, number),
  color: string
};

export default FacebookIcon;
