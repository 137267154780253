import { EnumRoles } from '@eeff/shared';
import {
  browserHistory,
  AUTH_TOKEN_KEY,
  IS_PUBLIC_SITE,
  AUTH_TOKEN_SHOP_KEY
} from '../utils/globals';
import { setCurrentUrlToRedirectAfterLogin } from '../utils/utilities';
import * as localStorageService from './localStorageService';
import { updateUserProfileAction, clearUserProfileAction } from '../redux/store-actions';
import store from '../redux/store';
import { queryAndUpdateUserData, clearLocalCache } from '../apollo/apollo-utils';

export const getAuthKey = () => {
  return IS_PUBLIC_SITE ? AUTH_TOKEN_SHOP_KEY : AUTH_TOKEN_KEY;
};

export const getCurrentToken = () => localStorageService.getItem(getAuthKey());

export function isLoggedIn() {
  return !!localStorageService.getItem(AUTH_TOKEN_KEY);
}

export function isLoggedInShop() {
  return !!localStorageService.getItem(AUTH_TOKEN_SHOP_KEY);
}

export function login({ token, userProfile }) {
  token && localStorageService.setItem(getAuthKey(), token);
  if (userProfile) {
    store.dispatch(updateUserProfileAction(userProfile));
  }
}

/**
 * If the user refreshed the page and him was already logged in
 * we need to re login him and update the redux store to reflect that state
 */
export const tryLoginFromLocalStorage = () => login({ token: getCurrentToken() });

export const isUserLoggedIn = () => {
  const loggedInAdmin = !IS_PUBLIC_SITE && isLoggedIn();
  const loggedInShop = IS_PUBLIC_SITE && isLoggedInShop();
  return loggedInAdmin || loggedInShop;
};

/**
 * If the user refreshed the page and him was already logged in
 * we need to retrieve the user profile again and update the redux store to reflect that state
 */
export const tryReloadlocalData = async () => {
  await clearLocalCache();

  if (isUserLoggedIn) {
    const user = await queryAndUpdateUserData();
    login({ userProfile: user });
  }

  return Promise.resolve();
};

export function alternateLogin(token) {
  token && localStorageService.setItem(getAuthKey(), token);
}

/**
 * Just resets the login token saved in the local storage
 * @param redirectBackAfterLogin
 */
export function lightLogout(redirectBackAfterLogin = true, goToLoginPage = false) {
  const loginRoute = '/login';
  store.dispatch(clearUserProfileAction());

  if (browserHistory.location.pathname !== loginRoute) {
    if (redirectBackAfterLogin) {
      setCurrentUrlToRedirectAfterLogin();
    }

    browserHistory.push(IS_PUBLIC_SITE ? (goToLoginPage ? loginRoute : '/home') : loginRoute);
  }
}

/**
 * Clear the auth token in LocalStorage, resets the redux store
 * and clears the Apollo store to flush its cache
 * @param {*} redirectBackAfterLogin
 */
export function logout(redirectBackAfterLogin = true, goToLoginPage = false) {
  lightLogout(redirectBackAfterLogin, goToLoginPage);
  localStorageService.setItem(getAuthKey(), '');
  setTimeout(() => {
    clearLocalCache();
  }, 300);
}

// EXAMPLE
export const isAdmin = () => {
  const { userProfileData } = store.getState();
  const roles = (userProfileData && userProfileData.roles) || [];
  return roles.indexOf(EnumRoles.ADMIN) !== -1;
};
