import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { withStyles } from '@mui/styles';
import shortLogo from '../../../public/shortlogo.png';
import logoSm from '../../../public/newlogo_sm.png';
import logoAlt from '../../../public/newlogo_inv_sm.png';
import logoAltHQ from '../../../public/newlogo_inv.png';
import logoHQ from '../../../public/newlogo.png';
import * as media from '../styles/media-queries';
import { isDark } from '../utilities';

const styles = () => ({
  logo: {
    maxWidth: '130px',
    position: 'relative',
    top: 2,
    ...media.xSmallScreen({
      maxWidth: '110px'
    }),
    ...media.print({
      width: '120px',
      maxWidth: '120px'
    })
  },
  shortLogoPrint: {
    maxWidth: '95px',
    ...media.print({
      maxWidth: '60px'
    })
  }
});

const Logo = ({
  externalLink,
  logoLink,
  className,
  classNameImg,
  style,
  styleImg,
  shortVersion,
  alt,
  theme,
  classes,
  hightQuality
}) => {
  const getLogoImg = () => {
    if (isDark(theme)) return logoAlt;
    if (shortVersion) {
      return shortLogo;
    } else if (alt) {
      return hightQuality ? logoAltHQ : logoAlt;
    }
    return hightQuality ? logoHQ : logoSm;
  };
  const Img = (
    <img
      alt="Logo"
      src={getLogoImg()}
      className={classnames(classes.logo, classNameImg, { [classes.shortLogoPrint]: shortVersion })}
      style={{ ...styleImg }}
    />
  );
  return (
    <div className={classnames(className)} style={{ ...style }}>
      {externalLink !== undefined ? (
        <a href={externalLink} target="_blank" rel="noreferrer noopener">
          {Img}
        </a>
      ) : (
        <Link to={logoLink}>{Img}</Link>
      )}
    </div>
  );
};

Logo.propTypes = {
  externalLink: PropTypes.string,
  logoLink: PropTypes.string,
  className: PropTypes.string,
  classNameImg: PropTypes.string,
  style: PropTypes.object,
  styleImg: PropTypes.object,
  shortVersion: PropTypes.bool,
  alt: PropTypes.bool,
  classes: PropTypes.object,
  theme: PropTypes.object,
  hightQuality: PropTypes.bool
};

Logo.defaultProps = {
  logoLink: '/'
};

export default withStyles(styles, { withTheme: true })(Logo);
