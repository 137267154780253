"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTotalUnitsSelected = exports.isItemCoveredWithPrimaryWarehouse = exports.updateSellPriceAndSellPriceNoTaxes = exports.updateRevenue = exports.computeSellPriceNoTax = exports.updateSellPrice = exports.reverseSellPriceNoTax = void 0;

var _sharedUtilities = require("../shared-utilities");

/**
 * if the user updated the sell price we should update revenue  and sellprice no taxes
 * but in this case the sellPriceNoTax should be calculated as sell/imp
 * Expects product.sellPrice to be instance of dineroJS
 */
var reverseSellPriceNoTax = function reverseSellPriceNoTax(product) {
  if (!product || !product.sellPrice) {
    return _sharedUtilities.DINEROJS_ZERO;
  }

  if (!(0, _sharedUtilities.isDineroObject)(product.sellPrice)) throw new Error('product.sellPrice SHOULD BE INSTANCE OF DINEROJS');
  var hasTaxes = product.productTaxes && product.productTaxes.length > 0; // sellPrice/imp only if it has one tax

  var imp = hasTaxes ? 1 + product.productTaxes[0].tax / 100 : 1;
  if (hasTaxes && product.productTaxes.length > 1) throw new Error('MORE THAN ONE TAX');
  return product.sellPrice.divide(imp);
};
/**
 * Recalculates sell price as sellPriceNoTaxes + (sellPriceNoTax * tax)
 * Expects sellPriceNoTaxes to be instance of dineroJS
 * @param {*} product
 * @return sellPrice as din object
 */


exports.reverseSellPriceNoTax = reverseSellPriceNoTax;

var updateSellPrice = function updateSellPrice(product) {
  if (!product || !product.sellPriceNoTaxes) {
    return _sharedUtilities.DINEROJS_ZERO;
  }

  if (!(0, _sharedUtilities.isDineroObject)(product.sellPriceNoTaxes)) throw new Error('product.sellPriceNoTaxes SHOULD BE INSTANCE OF DINEROJS');
  var hasTaxes = product.productTaxes && product.productTaxes.length > 0; // pricenotax*imp

  var totalTax = _sharedUtilities.DINEROJS_ZERO;

  if (hasTaxes) {
    product.productTaxes.forEach(function (v) {
      return totalTax = totalTax.add(product.sellPriceNoTaxes.multiply(v.tax / 100));
    });
  }

  return product.sellPriceNoTaxes.add(totalTax);
};
/**
 * Computes product sellPriceNoTax as costPrice + revenuePercentage
 * Expects revenuePercentage to be float or integer and costPrice to be instance of DineroJS
 * @param {*} product
 */


exports.updateSellPrice = updateSellPrice;

var computeSellPriceNoTax = function computeSellPriceNoTax(product) {
  if (!product || !product.costPrice) {
    return _sharedUtilities.DINEROJS_ZERO;
  }

  if (!(0, _sharedUtilities.isDineroObject)(product.costPrice)) throw new Error('product.costPrice SHOULD BE INSTANCE OF DINEROJS');
  var per = product.revenuePercentage ? 1 + parseFloat(product.revenuePercentage) / 100 : 1;
  return product.costPrice.multiply(per);
};
/**
 * Computes the revenue as (((priceNotax-costo)*100)/costo)
 * Expects sellPriceNoTaxes and costPrice to be instance of DineroJS
 * @param {*} product
 * @return new percentaje as float
 */


exports.computeSellPriceNoTax = computeSellPriceNoTax;

var updateRevenue = function updateRevenue(product) {
  if (!product || !product.costPrice || !product.sellPriceNoTaxes) return 0;
  if (!(0, _sharedUtilities.isDineroObject)(product.costPrice) || !(0, _sharedUtilities.isDineroObject)(product.sellPriceNoTaxes)) throw new Error('product.costPrice AND product.sellPriceNoTaxes SHOULD BE INSTANCE OF DINEROJS');

  if ((0, _sharedUtilities.roundDin)(product.costPrice) != 0) {
    //(((priceNotax-costo)*100)/costo)
    // round the cost to make it float, since dinero does not accept divide by another din
    var per = product.sellPriceNoTaxes.subtract(product.costPrice).multiply(100).divide((0, _sharedUtilities.roundDin)(product.costPrice));
    return per.toRoundedUnit(2);
  }

  return 0;
};
/**
 * Computes the new sell price without taxes and based on this one, the sell price
 * @param {*} product
 */


exports.updateRevenue = updateRevenue;

var updateSellPriceAndSellPriceNoTaxes = function updateSellPriceAndSellPriceNoTaxes(product) {
  product.sellPriceNoTaxes = computeSellPriceNoTax(product);
  product.sellPrice = updateSellPrice(product);
  return product;
};
/**
 * Chequeamos si el producto tiene stock en el deposito primario para cubrir la cantidad solicitada.
 * Si tiene un solo deposito y es el primario retorna true dado que se puede vender y dejar el stock
 * negativo
 * @param {*} quantity
 * @param {*} stock
 */


exports.updateSellPriceAndSellPriceNoTaxes = updateSellPriceAndSellPriceNoTaxes;

var isItemCoveredWithPrimaryWarehouse = function isItemCoveredWithPrimaryWarehouse() {
  var quantity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var stock = arguments.length > 1 ? arguments[1] : undefined;
  if (!stock || stock.length <= 0 || !Array.isArray(stock)) return false;
  if (stock.length == 1 && stock[0].isDefault) return true;
  var primary = stock.filter(function (w) {
    return w.isDefault;
  });
  if (primary.length <= 0) return false;
  return primary[0].units >= quantity;
};
/**
 * Computes the total units selected between all the warehouses
 * @param {*} stock
 */


exports.isItemCoveredWithPrimaryWarehouse = isItemCoveredWithPrimaryWarehouse;

var getTotalUnitsSelected = function getTotalUnitsSelected(stock) {
  if (!stock || stock.length <= 0 || !Array.isArray(stock)) return 0;
  return (stock || []).reduce(function (total, current) {
    return total + (current.selected || 0);
  }, 0);
};

exports.getTotalUnitsSelected = getTotalUnitsSelected;