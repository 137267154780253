import React from 'react';
import { bool, func, object } from 'prop-types';
import classNames from 'classnames';
import { AppBar, Toolbar } from '@mui/material/';
import { withStyles } from '@mui/styles';
import { Row } from 'simple-flexbox';
import Logo from './LogoComponent';
import { PRIVATE_BASE_PATH } from '../globals';
// import * as media from '../styles/media-queries';
import * as colors from '../styles/colors';

const materialStyles = (theme) => ({
  offlineHeader: {
    backgroundColor: colors.lightRed,
    color: colors.white
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  }
});

class SimpleHeader extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { openProfileMenu: null, searchValue: '', openNotifications: false };
  }

  handleMenuClose = () => this.setState({ openProfileMenu: null });

  redirectToProfile = () => {
    const { history } = this.props;
    this.handleMenuClose();
    history.push(`${PRIVATE_BASE_PATH}profile`);
  };

  redirectToLogout = () => {
    const { history } = this.props;
    this.handleMenuClose();
    history.push(`/logout`);
  };

  getTitleComponent = () => {
    const { offline } = this.props;
    return (
      <Row vertical="center" style={{ flex: 1 }}>
        <Logo alt={offline} />
      </Row>
    );
  };

  render() {
    const { classes, offline } = this.props;
    return (
      <AppBar
        color={'secondary'}
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.offlineHeader]: offline
        })}
      >
        <Toolbar disableGutters>{this.getTitleComponent()}</Toolbar>
      </AppBar>
    );
  }
}

SimpleHeader.propTypes = {
  classes: object,
  openDrawer: bool,
  offline: bool,
  handleDrawerOpen: func,
  user: object,
  history: object,
  location: object,
  simpleHeader: bool,
  markAllAsRead: func
};

export default withStyles(materialStyles, { withTheme: true })(SimpleHeader);
