"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cuitValidator = void 0;
var CHECKS = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];

var getDigits = function getDigits(cuit) {
  var digits = [];

  for (var i = 0; i < cuit.length; i++) {
    digits.push(Number.parseInt(cuit[i]));
  }

  return digits;
};

var getSumProd = function getSumProd(digits) {
  var sumProd = 0;

  for (var i = 0; i < CHECKS.length; i++) {
    sumProd = sumProd + CHECKS[i] * digits[i];
  }

  return sumProd;
};

var cuitValidator = function cuitValidator(cuit) {
  // Regex for cuit with dash ^\d{2}\-\d{8}\-\d{1}$
  // Regex for cuit without dash ^\d{11}$
  if (!cuit) return true;

  if (new RegExp(/^\d{2}-\d{8}-\d{1}$/).test(cuit) || new RegExp(/^\d{11}$/).test(cuit)) {
    var formatCuit = cuit.replace(/-/gi, '');
    var DIGITS = getDigits(formatCuit);
    var lastDigit = DIGITS[10];
    var verificationResult1 = getSumProd(DIGITS);
    var verificationResult2 = Math.floor(verificationResult1 / 11);
    var verificationResult3 = verificationResult1 - verificationResult2 * 11;
    var verificador = 0;

    if (verificationResult3 !== 0) {
      verificador = 11 - verificationResult3;
    }

    return verificador === lastDigit;
  }

  return false;
};

exports.cuitValidator = cuitValidator;