import { ApolloLink, HttpLink } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { createUploadLink } from 'apollo-upload-client';
import { sha256 } from 'crypto-hash';
import { setCurrentUrlToRedirectAfterLogin } from '../utils/utilities';
// import SerializingLink from 'apollo-link-serialize';
// import QueueLink from 'apollo-link-queue';
import { browserHistory, API_URL } from '../utils/globals';
// import { setTrackedQueries, getTrackedQueries } from '../services/localStorageService';
import store from '../redux/store';
import { addTrackedQuery, removeTrackedQuery } from '../redux/store-actions';
import { getAuthorizationString } from './apollo-utils';
import { logout } from '../services/authService';
import { PUBLIC_ROUTES } from '../constants/public_routes';

export const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: getAuthorizationString()
    }
  }));
  return forward(operation);
});

export const retryLink = new RetryLink();

// export const serializingLink = new SerializingLink();

export const trackerLink = new ApolloLink((operation, forward) => {
  // const { offline } = store.getState();
  if (forward === undefined) {
    return null;
  }
  const context = operation.getContext();
  const name = (context && context.operationName) || operation.operationName;
  const queryJSON = JSON.stringify(operation.query);
  const variablesJSON = JSON.stringify(operation.variables);
  const contextJSON = JSON.stringify({ opmisticResponse: context.opmisticResponse });
  const id = new Date().getTime(); //uuidv4();
  if (context.tracked !== undefined) {
    store.dispatch(
      addTrackedQuery({
        contextJSON,
        id,
        name,
        queryJSON,
        variablesJSON
      })
    );
  }
  return forward(operation).map((data) => {
    if (context.tracked !== undefined) {
      store.dispatch(removeTrackedQuery(id));
    }
    return data;
  });
});

// export const queueLink = new QueueLink();

/** REST of params
 * forward: ƒ (op)
 * operation:
    extensions: {persistedQuery: {…}}
    operationName: "publicCatalog"
    query: {kind: 'Document', definitions: Array(1), loc: {…}, publicCatalog: {…}}
    variables: {page: 0, limit: 25, filter: undefined, brandId: 'QnJhbmQ6MTA5Ng=='}
    getContext: ƒ ()
    setContext: ƒ (next)
    [[Prototype]]: Object
  * response: {}
 */
export const errorLink = onError(({ graphQLErrors, networkError }) => {
  const { offline } = store.getState();

  if (offline) return;
  if (graphQLErrors) {
    // console.log('graphql error-->', graphQLErrors);
    const e = (graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0]) || {};
    // console.log(e, 'link');

    if (
      e?.code === 401 &&
      e?.message === 'Unauthorized' &&
      !PUBLIC_ROUTES.some((r) => browserHistory?.location?.pathname?.includes(r))
    ) {
      console.log('ERROR LINK --> Unauthorized');
      logout(true, true);
    }
  }

  if (networkError) {
    console.log('network error-->', networkError);
    if (networkError.message === 'Failed to fetch') {
      setCurrentUrlToRedirectAfterLogin();
      if (!offline) {
        browserHistory.push('/error');
      }
    }
  }
});

export const httpLink = new HttpLink({
  uri: API_URL + 'graphql'
});

// both http and upload are terminating links, you can use one at a time
export const uploadLink = createUploadLink({
  uri: API_URL + 'graphql'
});

export const persistedQueryLink = createPersistedQueryLink({
  sha256
  // useGETForHashedQueries: true
});
