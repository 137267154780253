import React from 'react';
import { object } from 'prop-types';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import NotFoundIcon from '../../../public/error.png';
import * as media from '../styles/media-queries';
import * as colors from '../styles/colors';
import { getUrlToRedirectAfterLogin } from '../utilities';

const styles = () => ({
  container: {
    margin: '0 auto',
    maxWidth: 624,
    textAlign: 'center',
    paddingTop: 8
  },
  title: {
    margin: '4px 0px 16px 0px',
    fontFamily: 'Century Gothic',
    textAlign: 'center',
    color: colors.darkBlueGrey,
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: '36px',
    ...media.xSmallScreen({
      fontSize: 20,
      lineHeight: '30px'
    })
  },
  description: {
    color: colors.darkBlueGrey,
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center',
    marginBottom: 24,
    ...media.xSmallScreen({
      fontSize: 16,
      lineHeight: '24px'
    })
  }
});

const ErrorPage = ({ classes }) => {
  return (
    <div className={classes.container}>
      <img
        src={NotFoundIcon}
        {...(media.isXSmallScreen()
          ? {
              style: {
                width: 199,
                height: 176
              }
            }
          : {
              style: {
                width: 250
              }
            })}
      />
      <p className={classes.title}>Uh oh! Ha ocurrido un error!</p>
      <div className={classes.description}>
        Disculpe las molestias. Estamos trabajando para solucionarlo lo antes posible.
      </div>
      <Button
        variant="contained"
        onClick={() => {
          window.location = getUrlToRedirectAfterLogin();
        }}
        color="secondary"
      >
        Recargar
      </Button>
    </div>
  );
};

ErrorPage.propTypes = {
  classes: object
};

export default withStyles(styles, { whitTheme: true })(ErrorPage);
