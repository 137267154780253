import React, { lazy } from 'react';
import { object } from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import PageNotFound from '../utils/common-components/PageNotFound';
const ProfileContainer = lazy(() => import('../pages/ecommerce/WebUserProfile'));
const PublicProductsList = lazy(() => import('../pages/ecommerce/PublicProductsList'));
const Checkout = lazy(() => import('../pages/ecommerce/Checkout'));

const ShopPrivateRoutes = ({ match }) => {
  const me = match.url;
  return (
    <Switch>
      {/* <Route exact path={`${me}dashboard`} component={DashboardComponent} /> */}
      <Route exact path={`${me}profile`} component={ProfileContainer} />
      <Route exact path={`${me}catalog/:page`} component={PublicProductsList} />
      <Route exact path={`${me}checkout`} component={Checkout} />
      <Route component={PageNotFound} />
    </Switch>
  );
};

ShopPrivateRoutes.propTypes = {
  match: object
};

export default ShopPrivateRoutes;
