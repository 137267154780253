import React from 'react';
import { bool, func, object } from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { AppBar, IconButton, Toolbar, Badge } from '@mui/material/';
import { withStyles } from '@mui/styles';
import { Row } from 'simple-flexbox';
import { Menu as MenuIcon, NotificationsActive, Notifications } from '@mui/icons-material';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import Logo from './LogoComponent';
import {
  DRAWER_WITH,
  PRIVATE_BASE_PATH,
  ROUTES_WITH_SEARCHBAR,
  ENABLE_NOTIFICATIONS
} from '../globals';
import * as media from '../styles/media-queries';
import * as colors from '../styles/colors';
import * as typography from '../styles/typography';
import { isLoggedIn } from '../../services/authService';
import GlobalDebouncedFilterComponent from './GlobalDebouncedFilterComponent';
import NotificationsList from './Notifications';
import ProfileMenu from './ProfileMenu';

const materialStyles = (theme) => ({
  offlineHeader: {
    backgroundColor: colors.lightRed,
    color: colors.white
  },
  appBar: {
    zIndex: theme.zIndex.drawer,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: DRAWER_WITH,
    width: `calc(100% - ${DRAWER_WITH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
    ...media.xSmallScreen({
      marginLeft: 0,
      marginRight: 0
    })
  },
  hide: {
    display: 'none'
  },
  ...media.hideSmall,
  secondText: {
    ...typography.subTitle,
    fontStyle: 'italic',
    ...media.xSmallScreen({
      fontSize: 20,
      lineHeight: '24px'
    })
  },
  notificationsIcon: {
    color: 'black',
    fontSize: 30,
    ...media.xSmallScreen({
      fontSize: 25
    })
  }
});

class HeaderComponent extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { searchValue: '', openNotifications: false };
  }

  redirectToProfile = () => {
    const { history } = this.props;

    history.push(`${PRIVATE_BASE_PATH}profile`);
  };

  redirectToLogout = () => {
    const { history } = this.props;

    history.push(`/logout`);
  };

  markAllRead = () => {
    const { user, markAllAsRead } = this.props;
    this.setState({ openNotifications: true });
    if (user.notifications && user.notifications.unreadCount) {
      return markAllAsRead({
        optimisticResponse: {
          markAllAsRead: {
            __typename: 'markAllAsReadPayload',
            notifications: {
              ...user.notifications,
              list: user.notifications.list.map((n) => {
                const cloned = { ...n };
                cloned.readAt = new Date();
                return cloned;
              }),
              unreadCount: 0,
              __typename: 'UserNotifications'
            }
          }
        }
      });
    }
  };

  getDesktopContent = () => {
    const { openNotifications } = this.state;
    const { classes, location, history, offline } = this.props;
    const user = this.props.user;

    // TODO ESTO NO ANDA COMO QUIERO
    const hasSearchBar = ROUTES_WITH_SEARCHBAR.some((route) => {
      return location.pathname.includes(route);
    });

    return (
      <Row horizontal="end" style={{ flex: 1 }}>
        {offline && (
          <Row vertical="center" horizontal="center" style={{ flex: 1 }}>
            <SignalWifiOffIcon style={{ color: colors.white, fontSize: 25, marginRight: 5 }} />
            ACTUALMENTE SIN CONEXIÓN
          </Row>
        )}
        {hasSearchBar && !offline && (
          <Toolbar disableGutters>
            <GlobalDebouncedFilterComponent />
          </Toolbar>
        )}
        <Toolbar>
          {user?.notifications?.list?.length > 0 && ENABLE_NOTIFICATIONS && (
            <IconButton color="inherit" style={{ marginRight: 20 }} onClick={this.markAllRead}>
              <Badge badgeContent={user && user.notifications.unreadCount} color="error">
                {user && user.notifications.unreadCount ? (
                  <NotificationsActive className={classes.notificationsIcon} />
                ) : (
                  <Notifications className={classes.notificationsIcon} />
                )}
              </Badge>
            </IconButton>
          )}
          <ProfileMenu user={user} redirectToProfile={this.redirectToProfile} />
          <NotificationsList
            open={openNotifications}
            onClose={() => this.setState({ openNotifications: null })}
            history={history}
          />
        </Toolbar>
      </Row>
    );
  };

  getTitleComponent = () => {
    const { classes } = this.props;
    return (
      <div className={isLoggedIn() ? classes.hideSmall : null}>
        <Row vertical="center" style={{ flex: 1 }}>
          <Logo />
        </Row>
      </div>
    );
  };

  render() {
    const { classes, openDrawer, handleDrawerOpen, simpleHeader, offline } = this.props;
    return (
      <AppBar
        // color={'secondary'}
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: openDrawer,
          [classes.offlineHeader]: offline
        })}
      >
        <Toolbar disableGutters={!openDrawer}>
          {isLoggedIn() && !simpleHeader && (
            <IconButton
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={classNames(classes.menuButton, {
                [classes.hide]: openDrawer
              })}
            >
              <MenuIcon />
            </IconButton>
          )}
          {this.getTitleComponent()}
          {isLoggedIn() && !simpleHeader && this.getDesktopContent()}
        </Toolbar>
      </AppBar>
    );
  }
}

HeaderComponent.propTypes = {
  classes: object,
  openDrawer: bool,
  offline: bool,
  handleDrawerOpen: func,
  user: object,
  history: object,
  location: object,
  simpleHeader: bool,
  markAllAsRead: func
};

export default withRouter(withStyles(materialStyles, { withTheme: true })(HeaderComponent));
