"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isRequestApproved = exports.sanitizeRequestItemsForCreate = exports.sanitizeRequestItems = exports.computeSellReqTotals = exports.computeItemSellReqPriceAndTaxesFE = exports.calculateComposedSellReqPromoTotals = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _sharedUtilities = require("../shared-utilities");

var _sellHelper = require("./sell-helper");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// const MINIMUN_LEGAL_PRICE = din(0);
// console.log(din(3456.56).toFormat()) // the FN converts it to cents already

/**
 * RETURNS TOTALS AS DINERO OBJ
 * HACEMOS LO MISMO DE UN ITEM NORMAL, SOLO QUE ITERANDO CADA ITEM DE LA PROMO
 * Obtenido el precio de venta como ((costo+ganancia)*cantidad)-descPromo,
 * Usamos el campo sellPriceNoTaxes dado q puede haber sido redondeado por el usuario
 * iteramos luego sobre los posibles descuentos q se aplicaron sobre esa venta para calcular ese renglon descontado
 * se hace asi dado q el impuesto de cada prod puede ser diferente. POr eso no se aplican directamente sobre el total
 * de la venta, porque si uno paga 21 de IVA no es lo mismo q otro q paga 10.5
 * @param {*} item
 * @param {*} sellDiscounts
 * @param {*} taxPercentageToTake
 */
var calculateComposedSellReqPromoTotals = function calculateComposedSellReqPromoTotals(item) {
  var sellDiscounts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var taxPercentageToTake = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  var totalPromoNet = (0, _sharedUtilities.din)(0);
  var totalPromoDiscounted = (0, _sharedUtilities.din)(0);
  var totalPromo = (0, _sharedUtilities.din)(0);
  var totalPromoTaxes = (0, _sharedUtilities.din)(0);
  var promo = item.promo;
  var items = promo.items || promo.promoItems; // cross FE && BE compatibility

  var discount = promo.discount || +promo.discountPercentage; // cross FE && BE compatibility

  var promoInfo = [];

  for (var i = 0; i < items.length; i++) {
    var promoI = items[i]; // iteramos precioVentaRenglon = (((costo + ganancia) * cantidadRenglon)-descPromo) - * descuentoVenta

    var prod = promoI.product;
    var taxes = [];

    if (prod.productTaxes.length > 0) {
      taxes = (0, _sellHelper.recalculateTaxPercentage)(prod.productTaxes, taxPercentageToTake);
    }

    var _computeItemSellPrice = (0, _sellHelper.computeItemSellPriceAndTaxes)(item.selectedQuantity, discount, (0, _sharedUtilities.din)(prod.sellPriceNoTaxes), taxes, sellDiscounts),
        unitPrice = _computeItemSellPrice.unitPrice,
        totalTaxes = _computeItemSellPrice.totalTaxes,
        totalDiscounted = _computeItemSellPrice.totalDiscounted;

    totalPromo = totalPromo.add(unitPrice.multiply(promoI.quantity));
    totalPromoNet = totalPromoNet.add((0, _sharedUtilities.din)(prod.sellPriceNoTaxes).multiply(promoI.quantity));
    totalPromoTaxes = totalPromoTaxes.add(totalTaxes.multiply(promoI.quantity));
    totalPromoDiscounted = totalPromoDiscounted.add(totalDiscounted.multiply(promoI.quantity));
    var taxList = "".concat(taxes.map(function (t) {
      return t.tax;
    }).join(','));
    promoInfo.push({
      internalCode: prod.internalCode,
      name: prod.name,
      unitPrice: (0, _sharedUtilities.roundDin)(unitPrice),
      quantity: promoI.quantity,
      taxList: taxList
    });
  }

  return {
    totalPromo: totalPromo,
    totalPromoDiscounted: totalPromoDiscounted,
    totalPromoNet: totalPromoNet,
    totalPromoTaxes: totalPromoTaxes,
    promoInfo: promoInfo
  };
};
/**
 * Recalculates the sellPrice of an item base on its discount and the
 * sell discounts. Returns the following object:
 * ALL VALUES RETURNED AS DINERO OBJECT
 * return {
        unitPrice: unitPrice + totalTaxes,
        totalTaxes,
        totalDiscounted: itemSellPriceNoTaxes - unitPrice
    };
 */


exports.calculateComposedSellReqPromoTotals = calculateComposedSellReqPromoTotals;

var computeItemSellReqPriceAndTaxesFE = function computeItemSellReqPriceAndTaxesFE(item, sellDiscounts) {
  //si el usuario pone el precio a mano, entonces ya tiene impuestos y descuentos
  var filteredBrand = item.dirtyPrice ? null : item.brand;
  var discounts = (0, _sellHelper.unifySellAndBranDiscounts)(sellDiscounts, filteredBrand);
  return (0, _sellHelper.computeItemSellPriceAndTaxes)(item.selectedQuantity, item.discount, (0, _sharedUtilities.din)(item.sellPriceNoTaxes), item.productTaxes, discounts);
};
/**
 * ALL THE VALUES ARE RETURNED AS DINERO OBJ
 * Giving the discount list applied to a sell , we iterate the items and recalculated it price, total, taxes
 * and price discounted
 * @param {*} sellDiscounts list of discounts
 * @param {*} items list of sell items
 * @param {float} taxPercentageToTake
 */


exports.computeItemSellReqPriceAndTaxesFE = computeItemSellReqPriceAndTaxesFE;

var reApplyDiscountsToItemsSellReqFE = function reApplyDiscountsToItemsSellReqFE(items, sellDiscounts) {
  var taxPercentageToTake = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  sellDiscounts = (0, _sellHelper.resetCNDiscountsVirtualAmount)(sellDiscounts);
  return items.map(function (item) {
    var v = _objectSpread({}, item); // clone it JIC


    var unitPrice = (0, _sharedUtilities.din)(0);
    var totalDiscounted = (0, _sharedUtilities.din)(0);
    var totalTaxes = (0, _sharedUtilities.din)(0);

    if (!v.promoId) {
      if (item.sellPriceNoTaxes || item.sellPriceNoTaxes === 0) {
        //si el usuario pone el precio a mano, entonces ya tiene impuestos y descuentos
        var filteredBrand = item.dirtyPrice ? null : item.brand;
        var discounts = (0, _sellHelper.unifySellAndBranDiscounts)(sellDiscounts, filteredBrand);
        var data = (0, _sellHelper.computeItemSellPriceAndTaxes)(item.selectedQuantity, item.discount, (0, _sharedUtilities.din)(item.sellPriceNoTaxes), item.productTaxes, discounts);
        unitPrice = data.unitPrice;
        totalDiscounted = data.totalDiscounted;
        totalTaxes = data.totalTaxes;
      }
    } else {
      // is promo
      var _calculateComposedSel = calculateComposedSellReqPromoTotals(v, sellDiscounts, taxPercentageToTake),
          totalPromo = _calculateComposedSel.totalPromo,
          totalPromoTaxes = _calculateComposedSel.totalPromoTaxes,
          totalPromoDiscounted = _calculateComposedSel.totalPromoDiscounted;

      unitPrice = totalPromo;
      totalDiscounted = totalPromoDiscounted;
      totalTaxes = totalPromoTaxes;
    }

    v.totalTaxes = totalTaxes;
    v.totalDiscounted = totalDiscounted;
    v.unitPrice = unitPrice;
    v.total = unitPrice.multiply(v.selectedQuantity); // uso esta variable para almacenar el total descontado dado q la marca puede aplicar una lista

    v.totalDiscountPercentage = (0, _sellHelper.recalculateItemDiscountPercentage)(v);
    return v;
  });
};
/**
 * ALL VALUES WILL BE RETURNED AS DINERO OBJ
 * Calculates the totalNet, i.e the price without taxes and discounts,
 * the total sell value, ie. price with taxes and discounts,
 * total taxes and total discounts for the sell
 * return {
    totalWithDiscount: total,
    totalNet,
    totalSellTaxes,
    totalSellDiscounted
};
*/


var computeSellReqTotals = function computeSellReqTotals(items, sellDiscounts, taxPercentageToTake) {
  items = reApplyDiscountsToItemsSellReqFE(items, sellDiscounts, taxPercentageToTake);
  var totalNet = (0, _sharedUtilities.din)(0);
  var totalWithDiscount = (0, _sharedUtilities.din)(0);
  var totalSellTaxes = (0, _sharedUtilities.din)(0);
  var totalSellDiscounted = (0, _sharedUtilities.din)(0);

  for (var i = 0; i < items.length; i++) {
    totalWithDiscount = totalWithDiscount.add(items[i].total);
    totalNet = totalNet.add((0, _sharedUtilities.din)(items[i].sellPriceNoTaxes).multiply(items[i].selectedQuantity));
    totalSellTaxes = totalSellTaxes.add(items[i].totalTaxes.multiply(items[i].selectedQuantity));
    totalSellDiscounted = totalSellDiscounted.add(items[i].totalDiscounted.multiply(items[i].selectedQuantity));
  }

  return {
    totalWithDiscount: totalWithDiscount,
    totalNet: totalNet,
    totalSellTaxes: totalSellTaxes,
    totalSellDiscounted: totalSellDiscounted,
    items: items
  };
};

exports.computeSellReqTotals = computeSellReqTotals;

var sanitizeRequestItems = function sanitizeRequestItems(items) {
  return items.map(function (i) {
    var newItem = _objectSpread({}, i);

    delete newItem.unitPrice;
    delete newItem.totalRequest; // sell request item

    delete newItem.totalDiscounted;
    delete newItem.totalTaxes;
    delete newItem.taxes; // sell request item

    delete newItem.soldPrice; // sell request item

    delete newItem.sellPriceNoTaxes;
    delete newItem.productTaxes;
    delete newItem.productTaxesBkp;
    delete newItem.promo;
    delete newItem.quantity;
    delete newItem.isSelected;
    delete newItem.id;
    delete newItem.discount;
    delete newItem.productName;

    if (newItem.product) {
      newItem.productId = newItem.product.id;
    }

    delete newItem.product;
    delete newItem.total;
    delete newItem.__typename;
    delete newItem.code;
    delete newItem.name;

    if (newItem.brand) {
      delete newItem.brand.__typename;
      newItem.brand.discounts.forEach(function (d) {
        delete d.__typename;
        return d;
      });
    }

    return newItem;
  });
};

exports.sanitizeRequestItems = sanitizeRequestItems;

var sanitizeRequestItemsForCreate = function sanitizeRequestItemsForCreate(items) {
  return items.map(function (i) {
    var newItem = _objectSpread({}, i);

    newItem.unitPrice = (0, _sharedUtilities.roundDin)(newItem.unitPriceGeneric);
    delete newItem.isFromSellRequest;
    delete newItem.unitPriceGeneric;
    delete newItem.totalDiscounted;
    delete newItem.totalTaxes;
    delete newItem.productTaxes;
    delete newItem.productTaxesBkp;
    delete newItem.promo;
    delete newItem.id;
    delete newItem.discount;
    delete newItem.productName;
    delete newItem.product;
    delete newItem.total;
    delete newItem.__typename;

    if (newItem.brand) {
      delete newItem.brand.__typename;
      newItem.brand.discounts.forEach(function (d) {
        delete d.__typename;
        return d;
      });
    }

    return newItem;
  });
};

exports.sanitizeRequestItemsForCreate = sanitizeRequestItemsForCreate;

var isRequestApproved = function isRequestApproved(request) {
  return (request === null || request === void 0 ? void 0 : request.status) === 'APPROVED';
};

exports.isRequestApproved = isRequestApproved;