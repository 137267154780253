import React, { useEffect, useState } from 'react';
import { Drawer, Button, ListItem, List } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { any, func, object } from 'prop-types';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { Row } from 'simple-flexbox';
import { Clear } from '@mui/icons-material';
import { updateUserProfileAction } from '../../../redux/store-actions';
import { trackGaEvent } from '../../../utils/trackGaEvent';
import { fromGlobalId } from '../../../utils/utilities';
import { SITE_PRIVATE_BASE_PATH } from '../public-site-utils';
import { mapCartItemToGAItem } from '../../../utils/mapCartItemToGAItem';
import {
  showErrorMessage,
  showInfoMessage
} from '../../../utils/common-components/ToastNotificationEmitter';
import EFValidatorForm from '../../../utils/common-components/forms/EFValidatorForm';
import { useAddItemsToCart, useRemoveItemFromCart } from '../../../hooks/useCartOperations';
import CartDrawerItem from './CartDrawerItem';
import { getErrorMessage } from '../../../utils/errors';
import classes from './CartDrawer.module.scss';

const CartDrawer = ({ open, onClose, history }) => {
  const [removeItemFromCartMut] = useRemoveItemFromCart();
  const [saveItemsMut] = useAddItemsToCart();

  const dispatch = useDispatch();

  const user = useSelector((state) => state.userProfileData) || {};
  const userId = user?.id ? fromGlobalId(user.id).id : null;

  const [items, setItems] = useState(user?.shoppingCart?.items || []);

  useEffect(() => {
    setItems(user?.shoppingCart?.items || []);
  }, [user.shoppingCart]);

  const removeItem = async (e, item) => {
    try {
      e.stopPropagation();
      e.preventDefault();

      const optimisticResult = {
        ...(user?.shoppingCart || {}),
        itemsCount: ((user?.shoppingCart || {}).itemsCount || 0) - 1,
        items: user.shoppingCart?.items.filter((n) => n.id !== item.id),
        __typename: 'ShoppingCart'
      };

      dispatch(
        updateUserProfileAction({
          ...user,
          shoppingCart: optimisticResult
        })
      );

      await removeItemFromCartMut({
        variables: { itemId: item.id },
        optimisticResponse: {
          removeItemFromCart: {
            userProfile: {
              ...user,
              shoppingCart: optimisticResult,

              __typename: 'UserProfile'
            },
            __typename: 'removeItemFromCartPayload'
          }
        }
      });

      trackGaEvent('remove_from_cart', {
        user_id: userId,
        currency: 'ARS',
        value: parseFloat(item.quantity) * parseFloat(item.productPrice),
        items: [mapCartItemToGAItem(item, 0)]
      });

      showInfoMessage('Item Eliminado');
    } catch {
      showErrorMessage('Ha Ocurrido Un Error');
    }
  };

  const checkout = async () => {
    try {
      await saveItemsMut({
        variables: {
          updateOnly: true,
          items: items.map((i) => ({ productId: i.productId, quantity: parseFloat(i.quantity) }))
        }
      });

      trackGaEvent('begin_checkout', {
        user_id: userId,
        currency: 'ARS',
        value: user.shoppingCart.total,
        items: items.map(mapCartItemToGAItem)
      });

      history.push(`${SITE_PRIVATE_BASE_PATH}checkout`);

      onClose();
    } catch (e) {
      showErrorMessage(getErrorMessage(e));
    }
  };

  const handleUpdate = (e, index) => {
    const updated = [...items];
    updated[index] = { ...updated[index], quantity: e.target.value };
    setItems(updated);
  };

  return (
    <Drawer
      variant="temporary" // TODO me gustaria q sea permanente una columna al costado..
      anchor="right"
      className={cx(classes.drawer, {
        [classes.drawerOpen]: open
      })}
      classes={{
        paper: cx(classes.paper, {
          [classes.drawerOpen]: open
        })
      }}
      open={!!open && items.length > 0}
      onClose={onClose}
    >
      <EFValidatorForm onSubmit={checkout} onError={() => console.log} style={{ width: '100%' }}>
        <List className={classes.listContainer}>
          <ListItem disableGutters style={{ padding: 0, height: 25 }}>
            <Row horizontal="end" style={{ marginRight: 3, width: '100%' }}>
              <Button size="small" onClick={onClose}>
                <Clear />
              </Button>
            </Row>
          </ListItem>
          {items.map((n, i) => (
            <CartDrawerItem
              key={i}
              item={n}
              index={i}
              isLast={i === items.length - 1}
              onRemove={removeItem}
              onQuantityChanged={handleUpdate}
            />
          ))}
        </List>
        <Button
          type="submit"
          color="primary"
          fullWidth
          // onClick={checkout}
          variant="contained"
          className={classes.checkoutBtn}
        >
          TRAMITAR PEDIDO
        </Button>
      </EFValidatorForm>
    </Drawer>
  );
};

CartDrawer.propTypes = {
  open: any,
  onClose: func,
  history: object
};

export default withRouter(CartDrawer);
