import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Divider, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { Column, Row } from 'simple-flexbox';
import { formatCurrency } from '@eeff/shared';
import * as colors from '../../../utils/styles/colors';
import ProductThumbnail from './ProductThumbnail';
import TextField from '../../../utils/common-components/forms/TextField';
import classes from './CartDrawerItem.module.scss';

const CartDrawerItem = ({ item, index, isLast, onRemove, onQuantityChanged, fullWidth }) => {
  return (
    <Column
      // onClick={() => handleClick(notif)} // TODO abrir vista del item
      key={item.id}
      style={{ height: 'auto', marginBottom: 2 }}
    >
      <ListItem disableGutters className={cx(classes.listItem, classes.notifRow)}>
        <ListItemIcon classes={{ root: classes.iconRoot }}>
          <ProductThumbnail url={item.productPhotoUrl} classNameImg={classes.productImage} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Column>
              <Row vertical="center">
                <Column style={{ flex: 3 }} vertical="center">
                  <Typography
                    variant="h6"
                    className={cx(classes.productDescription, { [classes.fullWidth]: fullWidth })}
                  >
                    {item.productDescription}
                  </Typography>
                  <Divider className={classes.divider} />
                  <Typography className={classes.extraInfo}>
                    {item.brand}
                    {item.productCode ? ` - ${item.productCode}` : ''}
                  </Typography>
                  <Row vertical="center">
                    <Typography variant="subtitle1" className={classes.extraInfo}>
                      {item.quantity} x {formatCurrency(parseFloat(item.productPrice))}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.total}>
                      {formatCurrency(parseFloat(item.quantity) * parseFloat(item.productPrice))}
                    </Typography>
                  </Row>
                </Column>
                <Delete
                  color="error"
                  className={classes.clearIcon}
                  onClick={(e) => onRemove(e, item)}
                />
              </Row>
              <TextField
                value={item.quantity || '1'}
                name={`quantity-${index}`}
                type="number"
                step={1}
                className={classes.field}
                onChange={(e) => onQuantityChanged(e, index)}
              />
            </Column>
          }
        />
      </ListItem>

      {!isLast && <Divider style={{ border: `1px solid ${colors.darkBlueGrey}` }} />}
    </Column>
  );
};

CartDrawerItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  isLast: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onRemove: PropTypes.func,
  onQuantityChanged: PropTypes.func
};

export default CartDrawerItem;
