import { createBrowserHistory } from 'history';
import { sharedGlobals } from '@eeff/shared';

export const USER_TYPE = process.env.USER_TYPE || 'ADMIN';
export const ENABLE_PWA = process.env.ENABLE_PWA || true;
export const APP_ENV = process.env.APP_ENV || 'local';
export const IS_PRODUCTION = APP_ENV == 'production';
export const DRAWER_WITH = 250;
export const API_URL = process.env.API_URL;
export const PRIVATE_BASE_PATH = '/' + USER_TYPE.toLowerCase() + '/';
export const LOADING_MESSAGE = 'Cargando...';
export const DEFAULT_PAGE_LIMIT = sharedGlobals.DEFAULT_PAGE_LIMIT;
export const IS_ADMIN = USER_TYPE === 'ADMIN';
export const IS_SELLER = USER_TYPE === 'SELLER';
export const IS_PUBLIC_SITE = USER_TYPE === 'SHOP';
export const VERSION = '4.1.1';
export const APOLLO_CACHE_KEY = 'apollo-cache-ries';
export const AUTH_TOKEN_KEY = 'auth-token-ries';
export const AUTH_TOKEN_SHOP_KEY = 'auth-token-shop-ries';
export const PUBLIC_ASSETS_URL = 'https://rieslubricantes.com.ar/ries/public_assets/';
export const FOOTER_PRIVACY_TERMS_URL = `${PUBLIC_ASSETS_URL}policy.txt`;

// NO TOCAR ESTO, ES XQ EN PROD EN EL HOSTING TENEMOS Q CORRER LA APP DENTRO DE LA CARPETA site
export const PUBLIC_SITE_BASE_PATH = '/site/';

/**
 * Si se desea ejecutar la app react en un subdirectorio, hay q poner acá el nombre de la carpeta
 * https://zero2603.medium.com/how-to-deploy-reactjs-app-into-subdirectory-with-webpack-58d5ce32c655
 */
const myHistory = createBrowserHistory(
  IS_PUBLIC_SITE && IS_PRODUCTION ? { basename: PUBLIC_SITE_BASE_PATH } : undefined
);

// this line allows us to have the history object available everywhere in the project
// when you are outside a component, like in the authService or utilities file
export const browserHistory = myHistory;

export const ROUTES_WITH_SEARCHBAR = [
  '/shop/home',
  '/admin/suppliers/index',
  '/admin/sells/sellrequests/index',
  '/admin/sells/quoterequests/index'
];

export const ROUTES_SPECIAL_AUTH = ['fetchfile', 'sendinvoice'];

export const POLLING_INTERVAL = 360000; // 6min
export const POLLING_PROFILE_INTERVAL = 120000; // 2min
export const MARGEN_STOCK = 3;
export const ENABLE_OFFLINE_MODE = false;
export const ENABLE_DARK_THEME = false;
export const ENABLE_NOTIFICATIONS = !IS_PUBLIC_SITE; //TODO JUST FOR NOW

export const COMPANY_PHONE = '+54 9 291 448 5938';
export const COMPANY_NAME = 'RIES';
export const COMPANY_FULLNAME = 'RIES LUBRICANTES';
export const COMPANY_ADDRESS = 'Chile 1294 (Esq. Neuquén)';
export const COMPANY_TAX_ID = '20-34555402-6';
export const COMPANY_TAX_STARTED_AT = '01/02/2019';

export const SOFT_NAME = 'ALHAMBRA';
export const MERCADOPAGO_PUBLIC_KEY = process.env.MERCADOPAGO_PUBLIC_KEY;
export const RECAPTCHA_V3_SITE_KEY = process.env.RECAPTCHA_V3_SITE_KEY;

console.log(`<<<<< Starting ${USER_TYPE} >>>>>`);
