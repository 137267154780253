import { createTheme } from '@mui/material/styles';
import * as colors from './colors';
import './MuiThemeCssOverrides.scss';

const commonProps = {
  // Used by `getContrastText()` to maximize the contrast between the background and
  // the text.
  contrastThreshold: 3,
  typography: {
    useNextVariants: true
  },
  // TODO MIGRAR ESTO AL CSS
  components: {
    MuiInputAdornment: {
      positionStart: {
        marginRight: 3
      }
    },
    MuiTableCell: {
      sizeSmall: {
        padding: 4
      },
      root: {
        padding: 8,
        fontSize: 16,
        borderRight: '1px solid grey'
      }
    }
    // MuiTableRow: {
    //   root: {
    //     '& > :last-child': {
    //       borderRight: '1px solid grey'
    //     }
    //   }
    // },
    // MuiTableBody: {
    //   '& tr:last-child': {
    //     borderBottom: '1px solid grey'
    //   }
    // }
  }
};

const getLightTheme = () => {
  const { components, ...rest } = commonProps;
  return {
    palette: {
      primary: colors.lightThemePalette.primary,
      secondary: colors.lightThemePalette.secondary,
      error: {
        main: colors.red
      },
      background: {
        default: '#f4f6f9'
      }
    },
    components: {
      ...components
    },
    ...rest
  };
};

const getDarkTheme = () => {
  const { components, ...rest } = commonProps;
  return {
    palette: {
      mode: 'dark',
      secondary: colors.darkThemePalette.secondary,
      primary: colors.darkThemePalette.primary,
      error: {
        main: '#ff6e40'
      },
      background: {
        default: '#222222'
      },
      text: {
        primary: '#f4f6f9'
      }
    },
    components: {
      ...components,
      MuiAccordion: {
        root: {
          backgroundColor: '#757575'
        }
      },
      MuiDialog: {
        paper: {
          backgroundColor: '#757575'
        }
      }
    },
    ...rest
  };
};

export const lightTheme = createTheme(getLightTheme());
export const darkTheme = createTheme(getDarkTheme());
