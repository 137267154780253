"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  sharedGlobals: true,
  cuitValidator: true,
  equalArrays: true,
  productHelper: true,
  purchasesHelper: true,
  sellHelper: true,
  sellRequestHelper: true,
  getClientNameForFile: true
};
Object.defineProperty(exports, "cuitValidator", {
  enumerable: true,
  get: function get() {
    return _cuitValidator.cuitValidator;
  }
});
Object.defineProperty(exports, "equalArrays", {
  enumerable: true,
  get: function get() {
    return _equalArrays.equalArrays;
  }
});
Object.defineProperty(exports, "getClientNameForFile", {
  enumerable: true,
  get: function get() {
    return _getClientNameForFile.getClientNameForFile;
  }
});
exports.sellRequestHelper = exports.sellHelper = exports.purchasesHelper = exports.productHelper = exports.sharedGlobals = void 0;

var _sharedUtilities = require("./shared-utilities");

Object.keys(_sharedUtilities).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _sharedUtilities[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _sharedUtilities[key];
    }
  });
});

var _Enums = require("./enums/Enums");

Object.keys(_Enums).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Enums[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Enums[key];
    }
  });
});

var _enumsHelper = require("./enums/enums-helper");

Object.keys(_enumsHelper).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _enumsHelper[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _enumsHelper[key];
    }
  });
});

var _optimisticResponses = require("./optimistic-responses");

Object.keys(_optimisticResponses).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _optimisticResponses[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _optimisticResponses[key];
    }
  });
});

var sharedGlobals = _interopRequireWildcard(require("./shared-globals"));

exports.sharedGlobals = sharedGlobals;

var _cuitValidator = require("./cuitValidator");

var _equalArrays = require("./helpers/equalArrays");

var productHelper = _interopRequireWildcard(require("./helpers/product-helper"));

exports.productHelper = productHelper;

var purchasesHelper = _interopRequireWildcard(require("./helpers/purchase-invoice-helper"));

exports.purchasesHelper = purchasesHelper;

var sellHelper = _interopRequireWildcard(require("./helpers/sell-helper"));

exports.sellHelper = sellHelper;

var sellRequestHelper = _interopRequireWildcard(require("./helpers/sell-request-helper"));

exports.sellRequestHelper = sellRequestHelper;

var _getClientNameForFile = require("./helpers/getClientNameForFile");