import React, { useEffect, useState } from 'react';
import ChatBot from 'react-simple-chatbot';
import { EnumContacMessageHowHeard, EnumContacMessageSource } from '@eeff/shared';
import isEmail from 'is-email';
import sample from 'lodash.sample';
import useWindowSize from '@rooks/use-window-size';
import * as colors from '../../../utils/styles/colors';
import logo from '../assets/short-logo-sitio.png';
import { useCreateContactMessage } from '../../../graphql/mutations/hooks/useCreateContactMessage';
import { showErrorMessage } from '../../../utils/common-components/ToastNotificationEmitter';
import { COMPANY_PHONE } from '../../../utils/globals';
import './chatbotHeader.scss';
import { xSmallScreenWidth } from '../../../utils/styles/media-queries';

const ChatBotWrapper = () => {
  // const botName = sample(['Sofia', 'Fernando', 'Bernardo', 'Facundo', 'Martín', 'Evelyn']);
  const botName = 'Ries Lubricantes';
  const [open, setOpen] = useState(false);
  const { innerWidth } = useWindowSize();

  const [createMessage] = useCreateContactMessage();

  const getRandomInterestResponse = () =>
    sample([
      'Perfecto, por favor dejanos un télefono para poder contactarte y brindarte asesoramiento.',
      'Muy bien, ¿nos dejarías un teléfono para poder comunicarnos con vos y darte más información al respecto?',
      '¿Podrías brindarnos un teléfono para llamarte y asesorarte con respecto a tu consulta?'
    ]);

  const getRandomHi = () =>
    sample([
      'Comentanos en qué podemos ayudarte.',
      '¿En qué puedo ayudarte?',
      '¿Cuál es tu consulta?'
    ]);

  const getRandomClosure = () =>
    sample([
      '¡Muchas gracias! ¿A continuación podrás dejarnos tu correo electrónico al que recibirás toda la información sobre tus consultas',
      '¡Muchas gracias, pronto nos estaremos comunicando! Por favor dejanos un correo electrónico al cuál te enviaremos la información sobre tu consulta',
      '¡Muchas gracias, en el transcurso de la jornada nos estaremos comunicando! Por favor ingresa tu correo electrónico a continuación así podemos enviarte más información acerca de tu requerimiento.'
    ]);

  useEffect(() => {
    if (innerWidth > xSmallScreenWidth) {
      setTimeout(() => setOpen(true), 15000);
    }
  }, []);

  const onSubmit = async ({ steps }) => {
    try {
      const data = {
        firstName: steps?.getname?.value.trim(),
        message: `INTERESADO EN: ${steps?.categories?.value.trim()} -- ${steps?.getinterest?.value.trim()}`,
        howDidYouHear: steps?.gethowheard?.value.trim(),
        email: steps?.waitemail?.value.trim(),
        phone: steps?.getphone?.value.trim(),
        source: EnumContacMessageSource.CHAT_BOT
      };

      if (!data.phone || data.phone.length < 10)
        return showErrorMessage('EL teléfono debe tener entre 10 y 14 caracteres');

      await createMessage({
        variables: {
          data
        }
      });

      // setOpen(false); // por ahora prefiero no cerrarlo xq no se lee el numero nuestro.
    } catch (e) {
      console.log(e);
      showErrorMessage('Ha ocurrido un error');
    }
  };

  if (['/site', '/vcard'].some((r) => location.pathname.includes(r))) return null;

  return (
    <ChatBot
      opened={open}
      botAvatar={logo}
      recognitionLang="es"
      placeholder="Escribe tu mensaje..."
      headerTitle={botName}
      floatingStyle={{ background: colors.lightThemePalette.primary.light, width: 70, height: 70 }}
      bubbleStyle={{
        background: colors.themeGrey,
        color: colors.white,
        fontWeight: 500,
        fontSize: 16,
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        maxWidth: '75%'
      }}
      bubbleOptionStyle={{
        background: colors.themeLightGreen,
        fontSize: 16,
        fontWeight: 500,
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        color: colors.white
      }}
      floating
      steps={[
        {
          id: 'greetings',
          message: 'Bienvenido a Ries Lubricantes. Indicanos en que estás interesado.',
          trigger: 'categories'
        },
        {
          id: 'categories',
          options: [
            { value: 'SINTÉTICOS', label: 'SINTÉTICOS', trigger: 'askname' },
            { value: 'PESADOS', label: 'PESADOS', trigger: 'askname' },
            { value: 'LIVIANOS', label: 'LIVIANOS', trigger: 'askname' },
            { value: 'AGRÍCOLA', label: 'AGRÍCOLA', trigger: 'askname' },
            { value: 'COMPETICIÓN', label: 'COMPETICIÓN', trigger: 'askname' },
            { value: 'GRASAS', label: 'GRASAS', trigger: 'askname' }
          ],
          hideInput: true
        },
        {
          id: 'askname',
          message: `¿Con quién tengo el gusto de hablar?`,
          trigger: 'getname'
        },
        {
          id: 'getname',
          user: true,
          trigger: 'sayhi'
        },
        {
          id: 'sayhi',
          trigger: 'getinterest',
          message: `¡Hola {previousValue}! ${getRandomHi()}`
        },
        {
          id: 'getinterest',
          user: true,
          trigger: 'askphone'
        },
        {
          id: 'askphone',
          trigger: 'getphone',
          message: `${getRandomInterestResponse()}`
        },
        {
          id: 'getphone',
          user: true,
          trigger: 'askemail',
          placeholder: '+5492914373771 o 2914373771',
          validator: (value) => {
            if (isNaN(value) || value.toString().length > 14 || value.toString().length < 10) {
              return 'Número inválido';
            }
            return true;
          }
        },
        {
          id: 'askemail',
          trigger: 'mailconfirmation',
          message: getRandomClosure()
        },
        {
          id: 'mailconfirmation',
          options: [
            { value: 'NO', label: 'NO', trigger: 'askhowheard' },
            { value: 'SI', label: 'SI, tengo email.', trigger: 'getemail' }
          ],
          hideInput: true
        },
        {
          id: 'getemail',
          message: 'Ingresa tu correo electrónico:',
          trigger: 'waitemail'
        },
        {
          id: 'waitemail',
          user: true,
          trigger: 'askhowheard',
          validator: (value) => {
            if (!isEmail(value)) {
              return 'E-mail inválido';
            }
            return true;
          }
        },
        {
          id: 'askhowheard',
          message: '¿Cómo supiste de nosotros?',
          trigger: 'gethowheard'
        },
        {
          id: 'gethowheard',
          options: Object.keys(EnumContacMessageHowHeard).map((k) => ({
            value: k,
            label: EnumContacMessageHowHeard[k].replace(/_/g, ' '),
            trigger: 'end'
          })),
          hideInput: true
        },
        {
          id: 'end',
          end: true,
          message: `Gracias por comunicarte con Ries Lubricantes. Por cualquier otra consulta llamanos al ${COMPANY_PHONE}. Saludos!`
        }
      ]}
      handleEnd={onSubmit}
      toggleFloating={({ opened }) => setOpen(opened)}
    />
  );
};

export default ChatBotWrapper;
