import React, { useState } from 'react';
import { object, bool } from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import HeaderComponent from './HeaderContainer';
import SideBar from './SidebarComponent';
import { isLoggedIn } from '../../services/authService';
import SimpleHeader from './SimpleHeader';

import classes from './BarsComponent.module.scss';

const BarsComponent = ({ location, headerNoRedux, noSidebar }) => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const routesWithoutSideBar = [`/error`, '/login'];
  const hasSideBar = !routesWithoutSideBar.some((route) => route == location.pathname);
  const routesWithoutHeader = [`/error`];
  const simpleHeader = routesWithoutHeader.some((route) => route == location.pathname);

  return (
    <div className={classes.root}>
      {/**
          Page
          The <html> and <body> elements are updated to provide better page-wide defaults. More specifically:

          The margin in all browsers is removed.
          The default Material Design background color is applied. It's using theme.palette.background.default for standard devices and a white background for print devices.
          Layout
          box-sizing is set globally on the <html> element to border-box. Every element—including *::before and *::after are declared to inherit this property, which ensures that the declared width of the element is never exceeded due to padding or border.
          Typography
          Font antialiasing is enabled for better display of the Roboto font.
          No base font-size is declared on the <html>, but 16px is assumed (the browser default). You can learn more about the implications of changing the <html> default font size in the theme documentation page. 
        */}
      <CssBaseline />
      {headerNoRedux ? (
        <SimpleHeader />
      ) : (
        <HeaderComponent
          handleDrawerOpen={handleDrawerOpen}
          openDrawer={open}
          simpleHeader={simpleHeader}
        />
      )}

      {isLoggedIn() && hasSideBar && !noSidebar && (
        <SideBar
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
          openDrawer={open}
          location={location}
        />
      )}
    </div>
  );
};

BarsComponent.propTypes = {
  location: object,
  history: object,
  headerNoRedux: bool,
  noSidebar: bool
};

export default BarsComponent;
