import {
  UPDATE_USER_PROFILE_ACTION,
  CLEAR_USER_PROFILE_ACTION,
  SET_APOLLO_CLIENT,
  SET_SEARCH_FILTER,
  CLEAR_SEARCH_FILTER,
  SET_OPESI_FILTER,
  CLEAR_OPESI_FILTER,
  SET_OFFLINE_MODE,
  ADD_TRACKED_QUERY,
  REMOVE_TRACKED_QUERY,
  SET_THEME_MODE
} from './action-types';

export function updateUserProfileAction(payload) {
  return { type: UPDATE_USER_PROFILE_ACTION, payload };
}

export function clearUserProfileAction(payload) {
  return { type: CLEAR_USER_PROFILE_ACTION, payload };
}

export function setApolloClientAction(payload) {
  return { type: SET_APOLLO_CLIENT, payload };
}

export function setSearchFilter(payload) {
  return { type: SET_SEARCH_FILTER, payload };
}

export function clearSearchFilter(payload) {
  return { type: CLEAR_SEARCH_FILTER, payload };
}

export function toggleOpesiFilter(payload) {
  return { type: SET_OPESI_FILTER, payload };
}

export function clearOpesiFilter(payload) {
  return { type: CLEAR_OPESI_FILTER, payload };
}

export function setOfflineMode(payload) {
  return { type: SET_OFFLINE_MODE, payload };
}
export function addTrackedQuery(payload) {
  return { type: ADD_TRACKED_QUERY, payload };
}
export function removeTrackedQuery(payload) {
  return { type: REMOVE_TRACKED_QUERY, payload };
}

export function setThemeMode(payload) {
  return { type: SET_THEME_MODE, payload };
}
