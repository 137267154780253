import React, { useEffect } from 'react';
import { object, bool } from 'prop-types';
import cx from 'classnames';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import PublicSiteRouter from '../../routes/routes.public_site';
import { ENABLE_PWA, APP_ENV } from '../../utils/globals';
import * as serviceWorker from '../../pwa/service-worker';
import ShopBarsComponent from './ShopBarsComponent';
import ShopBreadCrumb from './ShopBreadCrumb';
import {
  hasSiteHeader,
  PUBLIC_SITE_FA_PIXEL_TRACKING_ID,
  PUBLIC_SITE_GA_TRACKING_ID,
  PUBLIC_SITE_GA_TRACKING_ID_TEST_MODE
} from './public-site-utils';
import FooterPublicSite from './FooterPublicSite';
import ChatBotWrapper from './components/ChatBotWrapper';
import classes from './PublicSiteApplication.module.scss';

const PublicSiteApplication = ({ location }) => {
  useEffect(() => {
    // Initialize Google Analytics
    console.log(
      'Initialize Google Analytics For ',
      APP_ENV === 'production' ? PUBLIC_SITE_GA_TRACKING_ID : PUBLIC_SITE_GA_TRACKING_ID_TEST_MODE
    );
    ReactGA.initialize(
      APP_ENV === 'production' ? PUBLIC_SITE_GA_TRACKING_ID : PUBLIC_SITE_GA_TRACKING_ID_TEST_MODE
    );

    if (APP_ENV === 'production') {
      const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: APP_ENV !== 'production' // enable logs
      };
      ReactPixel.init(PUBLIC_SITE_FA_PIXEL_TRACKING_ID, advancedMatching, options);
      // ReactPixel.revokeConsent(); // GDRP consent
      // onClick={ReactPixel.grantConsent}
    } else {
      console.log('>>> SKIPPING PIXEL ANALYTICS INITIALIZATION');
    }
  }, []);

  if (ENABLE_PWA) {
    serviceWorker.register();
    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker.register('/service-worker.js', {
    //     scope: '.'
    //   });
    // }
  } else {
    // serviceWorker.unregister();
  }

  return (
    <div>
      <ShopBarsComponent location={location} />
      <div
        className={cx(classes.innerContainer, {
          [classes.innerContainerPaddingTop]: hasSiteHeader(location.pathname)
        })}
      >
        <ShopBreadCrumb />
        <PublicSiteRouter />
        <ChatBotWrapper />
      </div>
      <FooterPublicSite />
    </div>
  );
};

PublicSiteApplication.propTypes = {
  location: object,
  classes: object,
  opesi: bool,
  history: object
};

export default PublicSiteApplication;
