export const UPDATE_USER_PROFILE_ACTION = 'UPDATE_USER_PROFILE_ACTION';
export const CLEAR_USER_PROFILE_ACTION = 'CLEAR_USER_PROFILE_ACTION';
export const SET_APOLLO_CLIENT = 'SET_APOLLO_CLIENT';
export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER';
export const CLEAR_SEARCH_FILTER = 'CLEAR_SEARCH_FILTER';
export const SET_OPESI_FILTER = 'SET_OPESI_FILTER';
export const CLEAR_OPESI_FILTER = 'CLEAR_OPESI_FILTER';
export const SET_OFFLINE_MODE = 'SET_OFFLINE_MODE';
export const ADD_TRACKED_QUERY = 'ADD_TRACKED_QUERY';
export const REMOVE_TRACKED_QUERY = 'REMOVE_TRACKED_QUERY';
export const SET_THEME_MODE = 'SET_THEME_MODE';
