import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Column } from 'simple-flexbox';
import OilBarrelIcon from '../../../utils/icons/oil-barrel';
import classes from './ProductThumbnail.module.scss';

const ProductThumbnail = ({ url, classNameImg }) => {
  if (url) {
    return (
      <div className={cx(classes.cover)}>
        <img className={cx(classes.productImage, classNameImg)} src={url} />
      </div>
    );
  }

  return (
    <Column className={cx(classes.cover)} vertical="center" horizontal="center">
      <OilBarrelIcon className={cx(classes.icon, classNameImg)} />
    </Column>
  );
};

ProductThumbnail.propTypes = {
  url: PropTypes.string,
  classNameImg: PropTypes.string
};

export default ProductThumbnail;
