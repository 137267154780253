import React, { useState } from 'react';
import { object, bool } from 'prop-types';
import { withStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import HeaderContainer from '../../utils/common-components/HeaderContainer';
import * as media from '../../utils/styles/media-queries';
import { hasSiteHeader } from './public-site-utils';
import PublicSiteSidebar from './PublicSiteSidebar';

const styles = (theme) => ({
  root: {
    display: 'flex',
    ...media.hideOnPrint
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: 15,
    ...media.xSmallScreen({
      marginLeft: -50
    })
  }
});

const ShopBarsComponent = ({ classes, location }) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  // TODO VER COMO PONER OTRO SIDEBAR PARA EL SHOP
  return (
    <div className={classes.root}>
      {/**
          Page
          The <html> and <body> elements are updated to provide better page-wide defaults. More specifically:

          The margin in all browsers is removed.
          The default Material Design background color is applied. It's using theme.palette.background.default for standard devices and a white background for print devices.
          Layout
          box-sizing is set globally on the <html> element to border-box. Every element—including *::before and *::after are declared to inherit this property, which ensures that the declared width of the element is never exceeded due to padding or border.
          Typography
          Font antialiasing is enabled for better display of the Roboto font.
          No base font-size is declared on the <html>, but 16px is assumed (the browser default). You can learn more about the implications of changing the <html> default font size in the theme documentation page. 
        */}
      <CssBaseline />
      {hasSiteHeader(location.pathname) && (
        <HeaderContainer handleDrawerOpen={() => setOpenDrawer(true)} openDrawer={openDrawer} />
      )}

      <PublicSiteSidebar
        handleDrawerOpen={() => setOpenDrawer(true)}
        handleDrawerClose={() => setOpenDrawer(false)}
        openDrawer={openDrawer}
      />
    </div>
  );
};

ShopBarsComponent.propTypes = {
  classes: object,
  children: object,
  location: object,
  history: object,
  headerNoRedux: bool,
  noSidebar: bool
};

export default withStyles(styles)(ShopBarsComponent);
