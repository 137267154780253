import React from 'react';
import { Button } from '@mui/material';
import NotFoundIcon from '../../../public/error.png';
import * as media from '../styles/media-queries';
import { getUrlToRedirectAfterLogin } from '../utilities';
import classes from './ErrorPageSite.module.scss';

const ErrorPageSite = () => {
  return (
    <div className={classes.container}>
      <img
        src={NotFoundIcon}
        {...(media.isXSmallScreen()
          ? {
              style: {
                width: 199,
                height: 176
              }
            }
          : {
              style: {
                width: 250
              }
            })}
      />
      <p className={classes.title}>Uh oh! Ha ocurrido un error!</p>
      <div className={classes.description}>
        Disculpe las molestias. Estamos trabajando para solucionarlo lo antes posible.
      </div>
      <Button
        variant="contained"
        onClick={() => {
          window.location = getUrlToRedirectAfterLogin();
        }}
        color="primary"
      >
        Recargar
      </Button>
    </div>
  );
};

export default ErrorPageSite;
