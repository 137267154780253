"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.equalArrays = void 0;

var _lodash = _interopRequireDefault(require("lodash.xorwith"));

var _lodash2 = _interopRequireDefault(require("lodash.isequal"));

var equalArrays = function equalArrays(a, b) {
  if (a.length !== b.length) return false;
  var diff = (0, _lodash["default"])(a, b, _lodash2["default"]);
  return !(diff !== null && diff !== void 0 && diff.length);
};

exports.equalArrays = equalArrays;