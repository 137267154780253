import React from 'react';
import { bool, object, func, string, any } from 'prop-types';
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  DialogTitle,
  useMediaQuery
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { alpha, useTheme } from '@mui/material/styles';
import { isXSmallScreen } from '../styles/media-queries';

const styles = (theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.secondary.light
  },
  row: {
    '&:nth-of-type(even)': {
      backgroundColor: alpha(theme.palette.primary.main, 0.15)
    }
  }
});

const ConfirmationDialog = ({
  open,
  onOk,
  onCancel,
  message,
  title,
  okLabel,
  dangerous,
  useFullScreen
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      PaperProps={{ style: { padding: 15 } }}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onCancel();
        }
      }}
      disableEscapeKeyDown
      fullScreen={fullScreen && useFullScreen}
      maxWidth={isXSmallScreen() && useFullScreen ? null : 'sm'}
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle id="confirmation-dialog-title">{title || ''}</DialogTitle>
      <DialogContent>
        {dangerous && message ? (
          <div dangerouslySetInnerHTML={message} />
        ) : (
          <div style={{ fontSize: 16 }}>{message}</div>
        )}
      </DialogContent>
      <DialogActions style={{ padding: 10 }}>
        {onCancel && (
          <Button variant="contained" size="medium" onClick={onCancel} color="secondary">
            NO
          </Button>
        )}
        {onOk && (
          <Button
            variant="contained"
            size="medium"
            onClick={onOk}
            color="primary"
            id="confirmation-dialog-ok"
          >
            {okLabel || 'SI'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  classes: object,
  className: string,
  message: any,
  dangerous: bool,
  open: bool,
  useFullScreen: bool,
  onOk: func,
  onCancel: func,
  title: string,
  okLabel: string
};

export default withStyles(styles, { withTheme: true })(ConfirmationDialog);
