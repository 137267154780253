import * as React from 'react';
import PropTypes from 'prop-types';
import { StyledEngineProvider } from '@mui/material/styles';

export default function GlobalCssPriority({ children }) {
  {
    /* Inject Emotion before JSS */
  }
  return <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>;
}

GlobalCssPriority.propTypes = {
  children: PropTypes.node
};
