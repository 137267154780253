import React, { useState } from 'react';
import { bool, object, func } from 'prop-types';
import { Typography, Button } from '@mui/material';
import { Row, Column } from 'simple-flexbox';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useMutation } from '@apollo/client';
import { EnumRoles } from '@eeff/shared';
import TextField from '../utils/common-components/forms/TextField';
import * as authService from '../services/authService';
import { getUrlToRedirectAfterLogin, isDark, setUrlToRedirectAfterLogin } from '../utils/utilities';
import { IS_PUBLIC_SITE, PRIVATE_BASE_PATH, USER_TYPE } from '../utils/globals';
import CenteredCard from '../utils/common-components/CenteredCardComponent';
import { showErrorMessage } from '../utils/common-components/ToastNotificationEmitter';
import { getErrorMessage } from '../utils/errors';
import loginGQL from '../graphql/mutations/profile/login.graphql';
import UnlockAnimation from '../lottie-animations/UnlockAnimation';
import { SITE_PRIVATE_BASE_PATH } from './ecommerce/public-site-utils';
import styles from './LoginComponent.module.scss';

const LoginComponent = ({ history, theme }) => {
  const redirectTo = history?.location?.state?.redirectTo;

  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  const [login, { loading }] = useMutation(loginGQL);

  if (loading)
    return (
      <Row vertical="center" horizontal="center">
        <UnlockAnimation style={{ width: 200 }} />
      </Row>
    );

  const onSubmit = async () => {
    try {
      const { data } = await login({
        variables: {
          user: user.toLowerCase(),
          password,
          type: IS_PUBLIC_SITE ? EnumRoles.WEB_CLIENT : USER_TYPE
        }
      });

      const response = data?.login || {};

      if (response?.result.success) {
        authService.login({ token: response.result.token, userProfile: response.profile });
        if (redirectTo) {
          return history.push(redirectTo);
        }

        const url = getUrlToRedirectAfterLogin();

        if (url) {
          setUrlToRedirectAfterLogin('');
          return history.push(url);
        }

        // TODO cuando el catalogo sea publico onda e commerce seguro haya q redirigir a otro lado
        if (IS_PUBLIC_SITE) {
          return history.push(`${SITE_PRIVATE_BASE_PATH}catalogo/1`);
        }

        return history.push(`${PRIVATE_BASE_PATH}dashboard`);
      } else {
        showErrorMessage('Inicio Sesión Inválido');
      }
    } catch (e) {
      console.log(e);
      showErrorMessage(getErrorMessage(e));
    }
  };

  return (
    <CenteredCard style={{ width: 300, paddingBottom: 24 }} raised>
      <Column>
        <Typography variant={'h4'} className={styles.title}>
          Iniciar Sesión
        </Typography>
        <ValidatorForm onSubmit={onSubmit} onError={(errors) => console.log(errors)}>
          <Column vertical="center">
            <TextField
              id="user"
              name="user"
              value={user}
              label={'Usuario'}
              autoFocus
              style={{ marginBottom: 30 }}
              validators={['required']}
              errorMessages={['Obligatorio']}
              onChange={(e) => setUser(e.target.value)}
              autoCapitalize="none"
              autoComplete="new-password"
            />
            <TextField
              id="password"
              type="password"
              value={password}
              name="password"
              label={'Contraseña'}
              style={{ marginBottom: 40 }}
              validators={['required']}
              errorMessages={['Obligatorio']}
              onChange={(e) => setPassword(e.target.value)}
              onEnterKey={onSubmit}
              autoCapitalize="none"
            />
            <Row horizontal="center" vertical="center">
              <Button
                id="login-btn"
                type="submit"
                variant="contained"
                color={isDark(theme) ? 'secondary' : 'primary'}
                size={'large'}
                styles={{ root: styles.button }}
              >
                Ingresar
              </Button>
            </Row>
          </Column>
        </ValidatorForm>
      </Column>
    </CenteredCard>
  );
};

LoginComponent.propTypes = {
  loading: bool,
  history: object,
  login: func,
  theme: object
};

export default LoginComponent;
