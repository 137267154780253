export const makePhoneNumberInternational = (number, countryCode = 54) => {
  const sanitized = (number || '').replace(/[^0-9]/gi, '');
  if (!sanitized || sanitized.length < 10 || sanitized.length > 13) return null;
  if (!sanitized.startsWith(countryCode)) {
    if (sanitized.startsWith('9') && sanitized.length == 11) {
      return `54${sanitized}`;
    } else if (sanitized.length == 10) {
      // console.log(`549${sanitized}`, 'kkk');
      return `549${sanitized}`;
    }
  }
  return sanitized;
};
