export const errorMessages = {
  401: 'ACCESO DENEGADO',
  402: 'CUENTA SUSPENDIDA',
  410: 'CONTRASEÑA INVÁLIDA',
  411: 'EL USUARIO YA FUE CONFIRMADO',
  412: 'ERROR DE VALIDACIÓN',
  500: 'ERROR INTERNO',
  501: 'CONFIGURACIÓN INVÁLIDA PARA EL SERVIDOR SMTP',
  600: 'USUARIO NO ENCONTRADO',
  601: 'CONTRASEÑA ANTERIOR INVÁLIDA',
  602: 'NÚMERO DE DOCUMENTO DUPLICADO',
  603: 'NÚMERO DE CUIT DUPLICADO',
  604: 'No Existe Persona Con ese ID',
  605: 'Falló La Conexión Con El Servidor De AFIP',
  606: 'AFIP RECHAZÓ LA FACTURA',
  614: 'PRODUCTO DESCATALOGADO',
  615: 'NO HAY NINGÚNA COMPRA INICIADA',
  618: 'LA MARCA YA EXISTE'
};

export const getErrorMessage = (err) => {
  const graphQLErrors = err?.graphQLErrors;
  const e = (graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0]) || {};
  return errorMessages[e.code] || e?.message || err?.message || 'HA OCURRIDO UN ERROR';
};

export const getErrorCode = (err) => {
  if (!err) return;
  const graphQLErrors = err?.graphQLErrors;
  const e = (graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0]) || {};
  return e?.code;
};

export const getErrorMeta = (err) => {
  if (!err) return;
  const graphQLErrors = err?.graphQLErrors;
  const e = (graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0]) || {};
  return e.meta ? JSON.parse(e.meta) : null;
};
