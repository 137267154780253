import React, { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import WrappedComponent from './HeaderComponent';
import userProfileGQL from '../../graphql/queries/userProfile.graphql';
import { POLLING_PROFILE_INTERVAL, IS_PUBLIC_SITE } from '../globals';
import { updateUserProfileAction } from '../../redux/store-actions';
import markAllReadMut from '../../graphql/mutations/notifications/markAllAsReadNotification.graphql';
import PublicSiteHeader from '../../pages/ecommerce/PublicSiteHeader';
import { isLoggedInShop } from '../../services/authService';

const HeaderContainer = (props) => {
  const profile = useSelector((state) => state.userProfileData) || null;
  const offline = useSelector((state) => state.offline);

  const { data, loading } = useQuery(userProfileGQL, {
    pollInterval: POLLING_PROFILE_INTERVAL,
    skip: !isLoggedInShop() && IS_PUBLIC_SITE
  });

  const [markAllAsRead, mutationData] = useMutation(markAllReadMut);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.viewer?.userProfile && !loading) {
      dispatch(updateUserProfileAction(data.viewer.userProfile));
    }
  }, [data]);

  useEffect(() => {
    if (mutationData?.data?.markAllAsRead) {
      dispatch(
        updateUserProfileAction({
          ...profile,
          notifications: mutationData.data.markAllAsRead.notifications
        })
      );
    }
  }, [mutationData]);

  const handleMarkAsRead = async () => {
    await markAllAsRead();
  };

  return IS_PUBLIC_SITE ? (
    <PublicSiteHeader
      {...props}
      user={profile}
      markAllAsRead={handleMarkAsRead}
      offline={offline}
    />
  ) : (
    <WrappedComponent
      {...props}
      user={profile}
      markAllAsRead={handleMarkAsRead}
      offline={offline}
    />
  );
};

export default HeaderContainer;
