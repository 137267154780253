import ReactGA from 'react-ga4';
/**
 * @param {string} event  Required. A recommended event or a custom event
 * @param {object} params Object containing all the params accepted for the event. The params below are for custom events only.
 * - args.category	String. Required.
 * - args.action	String. Required. A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
 * - args.label	String. Optional. More precise labelling of the related action. E.g. alongside the 'Added a component' action, we could add the name of a component as the label. E.g. 'Survey', 'Heading', 'Button', etc.
 * - args.value	Int. Optional. A means of recording a numerical value against an event. E.g. a rating, a score, etc.
 * - args.nonInteraction	Boolean. Optional. If an event is not triggered by a user interaction, but instead by our code (e.g. on page load), it should be flagged as a nonInteraction event to avoid skewing bounce rate data.
 * - args.transport	String. Optional. This specifies the transport mechanism with which hits will be sent. Valid values include 'beacon', 'xhr', or 'image'.
 * - options.dimension{1...200}	any Optional
 * - options.metric{1...200}	any Optional
 *
 * Para todos los eventos se puede mandar el param user_id que es reservado y se usa para trackear la session automaticamente
 */
export const trackGaEvent = (event, params) => {
  ReactGA.event(event, params);
};
