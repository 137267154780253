import React from 'react';
import { object, bool, func } from 'prop-types';
import classNames from 'classnames';
import { Row } from 'simple-flexbox';
import {
  Drawer,
  List,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Divider,
  IconButton,
  Tooltip
} from '@mui/material';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import {
  ExitToApp,
  Dashboard as DashboardIcon,
  Settings as SettingsIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Group,
  LocalParking,
  Work,
  ShoppingCart,
  AccountBalanceWallet,
  AddShoppingCart,
  Storefront,
  Assignment,
  MarkunreadMailbox,
  ListAlt,
  PersonAdd
} from '@mui/icons-material';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { DRAWER_WITH, PRIVATE_BASE_PATH, IS_ADMIN, IS_SELLER } from '../globals';
import * as media from '../styles/media-queries';
import Logo from './LogoComponent';
import { themeGrey } from '../styles/colors';

const styles = (theme) => ({
  drawer: {
    width: DRAWER_WITH,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: DRAWER_WITH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '70px',
    [theme.breakpoints.up('sm')]: {
      width: '70px'
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  paper: {
    background: theme.palette.primary.main
  },
  ...media.hideLarge,
  closeMenuIcon: {
    ...media.xSmallScreen({
      flex: 1,
      justifyContent: 'flex-end'
    })
  },
  icon: {
    fontSize: 40
  },
  iconContainer: {
    marginRight: 5,
    ...media.xSmallScreen({
      marginRight: 0,
      minWidth: 50
    })
  }
});

const SideBar = ({ classes, theme, handleDrawerClose, openDrawer }) => {
  return (
    <Drawer
      variant={media.isSmallScreen() ? 'temporary' : 'permanent'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: openDrawer,
        [classes.drawerClose]: !openDrawer
      })}
      classes={{
        paper: classNames(classes.paper, {
          [classes.drawerOpen]: openDrawer,
          [classes.drawerClose]: !openDrawer
        })
      }}
      open={openDrawer}
      onClose={handleDrawerClose}
    >
      <Row className={classes.toolbar}>
        <Logo className={classes.hideLarge} style={{ flex: 1 }} alt />
        <IconButton onClick={handleDrawerClose} className={classes.closeMenuIcon}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </Row>
      <Divider />
      <List style={{ marginTop: 30 }}>
        <Tooltip title="DASHBOARD" placement="right">
          <Link to={`${PRIVATE_BASE_PATH}dashboard`} style={{ textDecoration: 'none' }}>
            <ListItemButton onClick={handleDrawerClose}>
              <ListItemIcon
                className={classNames({
                  [classes.iconContainer]: openDrawer
                })}
              >
                <DashboardIcon color="secondary" className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                primary={'Dashboard'}
                primaryTypographyProps={{ style: { color: themeGrey } }}
              />
            </ListItemButton>
          </Link>
        </Tooltip>
        <Tooltip title="VENTAS" placement="right">
          <Link
            to={
              IS_ADMIN ? `${PRIVATE_BASE_PATH}sells/dashboard` : `${PRIVATE_BASE_PATH}sells/index/1`
            }
            style={{ textDecoration: 'none' }}
          >
            <ListItemButton id="sells-sidebar-menu" onClick={handleDrawerClose}>
              <ListItemIcon
                className={classNames({
                  [classes.iconContainer]: openDrawer
                })}
              >
                <ShoppingCart color="secondary" className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                primary={'Ventas'}
                primaryTypographyProps={{ style: { color: themeGrey } }}
              />
            </ListItemButton>
          </Link>
        </Tooltip>

        {IS_ADMIN && (
          <Tooltip title="COMPRAS" placement="right">
            <Link to={`${PRIVATE_BASE_PATH}purchases/dashboard`} style={{ textDecoration: 'none' }}>
              <ListItemButton onClick={handleDrawerClose}>
                <ListItemIcon
                  className={classNames({
                    [classes.iconContainer]: openDrawer
                  })}
                >
                  <AddShoppingCart color="secondary" className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  primary={'Compras'}
                  primaryTypographyProps={{ style: { color: themeGrey } }}
                />
              </ListItemButton>
            </Link>
          </Tooltip>
        )}
        {IS_SELLER && (
          <Tooltip title="NOTA DE VENTA" placement="right">
            <Link
              to={`${PRIVATE_BASE_PATH}sells/sellrequests/index/1`}
              style={{ textDecoration: 'none' }}
            >
              <ListItemButton onClick={handleDrawerClose}>
                <ListItemIcon
                  className={classNames({
                    [classes.iconContainer]: openDrawer
                  })}
                >
                  <Assignment color="secondary" className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  primary={'Nota Venta'}
                  primaryTypographyProps={{ style: { color: themeGrey } }}
                />
              </ListItemButton>
            </Link>
          </Tooltip>
        )}
        {IS_SELLER && (
          <Tooltip title="PRESUPUESTOS" placement="right">
            <Link
              to={`${PRIVATE_BASE_PATH}sells/quoterequests/index/1`}
              style={{ textDecoration: 'none' }}
            >
              <ListItemButton onClick={handleDrawerClose}>
                <ListItemIcon
                  className={classNames({
                    [classes.iconContainer]: openDrawer
                  })}
                >
                  <LocalParking color="secondary" className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  primary={'Presupuestos'}
                  primaryTypographyProps={{ style: { color: themeGrey } }}
                />
              </ListItemButton>
            </Link>
          </Tooltip>
        )}
        <Tooltip title="CAJA" placement="right">
          <Link to={`${PRIVATE_BASE_PATH}funds/dashboard`} style={{ textDecoration: 'none' }}>
            <ListItemButton onClick={handleDrawerClose}>
              <ListItemIcon
                className={classNames({
                  [classes.iconContainer]: openDrawer
                })}
              >
                <AccountBalanceWallet color="secondary" className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                primary={'Cajas'}
                primaryTypographyProps={{ style: { color: themeGrey } }}
              />
            </ListItemButton>
          </Link>
        </Tooltip>
        <Divider />
        <Tooltip title="CLIENTES" placement="right">
          <Link to={`${PRIVATE_BASE_PATH}clients/index/1`} style={{ textDecoration: 'none' }}>
            <ListItemButton id="clients-sidebar-menu" onClick={handleDrawerClose}>
              <ListItemIcon
                className={classNames({
                  [classes.iconContainer]: openDrawer
                })}
              >
                <Group color="secondary" className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                primary={'Clientes'}
                primaryTypographyProps={{ style: { color: themeGrey } }}
              />
            </ListItemButton>
          </Link>
        </Tooltip>
        {IS_ADMIN && (
          <Tooltip title="PROVEEDORES" placement="right">
            <Link to={`${PRIVATE_BASE_PATH}suppliers/index/1`} style={{ textDecoration: 'none' }}>
              <ListItemButton onClick={handleDrawerClose}>
                <ListItemIcon
                  className={classNames({
                    [classes.iconContainer]: openDrawer
                  })}
                >
                  <Work color="secondary" className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  primary={'Proveedores'}
                  primaryTypographyProps={{ style: { color: themeGrey } }}
                />
              </ListItemButton>
            </Link>
          </Tooltip>
        )}
        <Tooltip title="ARTÍCULOS" placement="right">
          <Link to={`${PRIVATE_BASE_PATH}products/index/1`} style={{ textDecoration: 'none' }}>
            <ListItemButton id="products-sidebar-menu" onClick={handleDrawerClose}>
              <ListItemIcon
                className={classNames({
                  [classes.iconContainer]: openDrawer
                })}
              >
                <Storefront color="secondary" className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                primary={'Artículos'}
                primaryTypographyProps={{ style: { color: themeGrey } }}
              />
            </ListItemButton>
          </Link>
        </Tooltip>
        {IS_ADMIN && (
          <Tooltip title="Mensajes Contacto" placement="right">
            <Link
              to={`${PRIVATE_BASE_PATH}contactmessages/index/1`}
              style={{ textDecoration: 'none' }}
            >
              <ListItemButton onClick={handleDrawerClose}>
                <ListItemIcon
                  className={classNames({
                    [classes.iconContainer]: openDrawer
                  })}
                >
                  <MarkunreadMailbox color="secondary" className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  primary={'Mensajes Contacto'}
                  primaryTypographyProps={{ style: { color: themeGrey } }}
                />
              </ListItemButton>
            </Link>
          </Tooltip>
        )}
        <Tooltip title="Agenda" placement="right">
          <Link to={`${PRIVATE_BASE_PATH}useragenda`} style={{ textDecoration: 'none' }}>
            <ListItemButton onClick={handleDrawerClose}>
              <ListItemIcon
                className={classNames({
                  [classes.iconContainer]: openDrawer
                })}
              >
                <EventNoteIcon color="secondary" className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                primary={'Agenda'}
                primaryTypographyProps={{ style: { color: themeGrey } }}
              />
            </ListItemButton>
          </Link>
        </Tooltip>
        <Tooltip title="Tareas" placement="right">
          <Link to={`${PRIVATE_BASE_PATH}usertasks`} style={{ textDecoration: 'none' }}>
            <ListItemButton onClick={handleDrawerClose}>
              <ListItemIcon
                className={classNames({
                  [classes.iconContainer]: openDrawer
                })}
              >
                <ListAlt color="secondary" className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                primary={'Tareas'}
                primaryTypographyProps={{ style: { color: themeGrey } }}
              />
            </ListItemButton>
          </Link>
        </Tooltip>
        {IS_ADMIN && (
          <Tooltip title="Usuarios" placement="right">
            <Link to={`${PRIVATE_BASE_PATH}users/`} style={{ textDecoration: 'none' }}>
              <ListItemButton onClick={handleDrawerClose}>
                <ListItemIcon
                  className={classNames({
                    [classes.iconContainer]: openDrawer
                  })}
                >
                  <PersonAdd color="secondary" className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  primary={'Usuarios'}
                  primaryTypographyProps={{ style: { color: themeGrey } }}
                />
              </ListItemButton>
            </Link>
          </Tooltip>
        )}
      </List>
      <Divider />
      <Tooltip title="CONFIGURACIÓN" placement="right">
        <Link to={`${PRIVATE_BASE_PATH}settings`} style={{ textDecoration: 'none' }}>
          <ListItemButton id="sidebar-settings-menu" onClick={handleDrawerClose}>
            <ListItemIcon
              className={classNames({
                [classes.iconContainer]: openDrawer
              })}
            >
              <SettingsIcon color="secondary" className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primary={'Configuración'}
              primaryTypographyProps={{ style: { color: themeGrey } }}
            />
          </ListItemButton>
        </Link>
      </Tooltip>
      <Tooltip title="CERRAR SESIÓN" placement="right">
        <Link to={`/logout`} style={{ textDecoration: 'none' }}>
          <ListItemButton onClick={handleDrawerClose}>
            <ListItemIcon
              className={classNames({
                [classes.iconContainer]: openDrawer
              })}
            >
              <ExitToApp color="secondary" className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primary={'Cerrar Sessión'}
              primaryTypographyProps={{ style: { color: themeGrey } }}
            />
          </ListItemButton>
        </Link>
      </Tooltip>
    </Drawer>
  );
};

SideBar.propTypes = {
  location: object,
  classes: object,
  openDrawer: bool,
  handleDrawerClose: func,
  theme: object,
  history: object
};

export default withRouter(withStyles(styles, { withTheme: true })(SideBar));
