import React, { useState } from 'react';
import { bool, func, object } from 'prop-types';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { AppBar, IconButton, Toolbar, Badge } from '@mui/material/';
import { Row } from 'simple-flexbox';
import {
  NotificationsActive,
  Notifications,
  Menu as MenuIcon,
  ShoppingBasket,
  Instagram
} from '@mui/icons-material';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import { Link } from 'react-router-dom';
import { ENABLE_NOTIFICATIONS } from '../../utils/globals';
import * as media from '../../utils/styles/media-queries';
import * as colors from '../../utils/styles/colors';
import NotificationsList from '../../utils/common-components/Notifications';
import { isLoggedInShop } from '../../services/authService';
import { SITE_PRIVATE_BASE_PATH } from './public-site-utils';
import ProfileMenu from '../../utils/common-components/ProfileMenu';
import LogoSitio from './components/LogoSitio';
import CartDrawer from './components/CartDrawer';
import { useIsFeatureEnabled } from '../../hooks/useIsFeatureEnabled';
import { trackGaEvent } from '../../utils/trackGaEvent';
import FacebookIcon from '../../utils/icons/facebook';
import classes from './PublicSiteHeader.module.scss';

const PublicSiteHeader = ({
  history,
  user,
  markAllAsRead,
  offline,
  openDrawer,
  handleDrawerOpen
}) => {
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openCart, setOpenCart] = useState(false);

  const redirectToProfile = () => {
    history.push(`${SITE_PRIVATE_BASE_PATH}profile`);
  };

  const markAllRead = () => {
    setOpenNotifications(true);
    if (user.notifications && user.notifications.unreadCount) {
      return markAllAsRead({
        optimisticResponse: {
          markAllAsRead: {
            __typename: 'markAllAsReadPayload',
            notifications: {
              ...user.notifications,
              list: user.notifications.list.map((n) => {
                n.readAt = new Date();
                return n;
              }),
              unreadCount: 0,
              __typename: 'UserNotifications'
            }
          }
        }
      });
    }
  };

  const getCartStatus = () => {
    const isCartFeatureEnabled = useIsFeatureEnabled('SHOPPING_CART');
    if (!isCartFeatureEnabled) return null;
    const itemsCount = user?.shoppingCart?.itemsCount;
    return (
      <IconButton color="inherit" onClick={() => setOpenCart(true)}>
        <Badge badgeContent={itemsCount} color="error">
          <ShoppingBasket className={classes.basketIcon} />
        </Badge>
      </IconButton>
    );
  };

  const NotificationsToolBarItem = () => (
    <>
      {user?.notifications?.list?.length > 0 && ENABLE_NOTIFICATIONS && (
        <IconButton color="inherit" onClick={markAllRead}>
          <Badge badgeContent={user?.notifications?.unreadCount} color="error">
            {user?.notifications?.unreadCount ? (
              <NotificationsActive className={classes.notificationsIcon} />
            ) : (
              <Notifications className={classes.notificationsIcon} />
            )}
          </Badge>
        </IconButton>
      )}
    </>
  );

  const ProfileMenuItem = () => (
    <>
      {isLoggedInShop() && (
        <>
          <ProfileMenu user={user} redirectToProfile={redirectToProfile} />
          <NotificationsToolBarItem />
          {getCartStatus()}
          <NotificationsList open={openNotifications} onClose={() => setOpenNotifications(false)} />
          <CartDrawer open={openCart} onClose={() => setOpenCart(false)} />
        </>
      )}
    </>
  );

  const socialToolbar = () => (
    <Toolbar>
      <Instagram
        className={classes.socialIcon}
        onClick={() => {
          window.open('https://www.instagram.com/rieslubricantes/', '_blank');
          trackGaEvent('FooterSocial', { action: 'Instagram clicked' });
        }}
      />
      <FacebookIcon
        className={classes.socialIcon}
        onClick={() => {
          window.open('https://www.facebook.com/rieslubricantes', '_blank');
          trackGaEvent('FooterSocial', { action: 'Facebook clicked' });
        }}
      />
    </Toolbar>
  );

  const getDesktopContent = () => {
    return (
      <Row style={{ minWidth: '100%' }}>
        {offline && (
          <Row vertical="center" horizontal="center" style={{ flex: 1 }}>
            <SignalWifiOffIcon style={{ color: colors.white, fontSize: 25, marginRight: 5 }} />
            ACTUALMENTE SIN CONEXIÓN
          </Row>
        )}
        {!offline && (
          <Toolbar>
            <Link to={`/home`} className={classes.toolbarSectionTitle}>
              INICIO
            </Link>
            <Link to={`/whoweare`} className={classes.toolbarSectionTitle}>
              NOSOTROS
            </Link>
            {/* <Link to={`/brands`} className={classes.toolbarSectionTitle}>
              MARCAS
            </Link> */}
            <Link
              to={{
                pathname: `${SITE_PRIVATE_BASE_PATH}catalog/1`,
                state: { redirectTo: `${SITE_PRIVATE_BASE_PATH}catalog/1` }
              }}
              className={classes.toolbarSectionTitle}
            >
              CATÁLOGO
            </Link>
            <Link to={`/contact`} className={classes.toolbarSectionTitle}>
              CONTACTO
            </Link>

            {/* <Link to={`${PRIVATE_BASE_PATH}home`} className={classes.toolbarSectionTitle}>
              TIENDA
            </Link> */}
          </Toolbar>
        )}
        {socialToolbar()}
        <Toolbar>
          <ProfileMenuItem />
        </Toolbar>
      </Row>
    );
  };

  return (
    <AppBar className={classes.navbar} position="fixed">
      <Toolbar disableGutters={!openDrawer}>
        {media.isXSmallScreen() && (
          <IconButton
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            className={cx(classes.menuButton, {
              [classes.hide]: openDrawer
            })}
          >
            <MenuIcon />
          </IconButton>
        )}
        {!openDrawer && <LogoSitio classNameImg={classes.logo} />}
        {!media.isXSmallScreen() && getDesktopContent()}
        {media.isXSmallScreen() && socialToolbar()}
      </Toolbar>
    </AppBar>
  );
};

PublicSiteHeader.propTypes = {
  classes: object,
  offline: bool,
  user: object,
  history: object,
  location: object,
  simpleHeader: bool,
  markAllAsRead: func,
  openDrawer: bool,
  handleDrawerOpen: func
};

export default withRouter(PublicSiteHeader);
