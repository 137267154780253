import React from 'react';
import { Typography, Divider } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Column, Row } from 'simple-flexbox';
import { object } from 'prop-types';
import * as media from '../styles/media-queries';
import * as colors from '../styles/colors';
import { VERSION, FOOTER_PRIVACY_TERMS_URL, COMPANY_FULLNAME, SOFT_NAME } from '../globals';

const styles = () => ({
  hide: {
    ...media.hideOnPrint,
    minHeight: '15vh'
  },
  margin: {
    marginLeft: 5,
    ...media.xSmallScreen({
      marginTop: 4,
      marginLeft: 0
    })
  }
});

const Footer = ({ classes }) => {
  return (
    <div className={classes.hide}>
      <Column style={{ marginTop: 30 }}>
        <Divider style={{ marginBottom: 30 }} />
        <Row
          horizontal="center"
          vertical="center"
          breakpoints={{
            [media.xSmallScreenWidth]: 'column'
          }}
        >
          <Typography variant="subtitle1" align={'center'} style={{ fontSize: 16 }}>
            {COMPANY_FULLNAME}™ {new Date().getFullYear()}
          </Typography>
          <Typography className={classes.margin} variant="subtitle1" align={'center'}>
            {media.isXSmallScreen() ? '' : ' - '}Todos los derechos reservados -
          </Typography>
          <Typography className={classes.margin} variant="subtitle1" align={'center'}>
            <a
              href={FOOTER_PRIVACY_TERMS_URL}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: colors.link }}
            >
              Pol&iacute;tica de Privacidad
            </a>
          </Typography>
        </Row>
        <Typography variant="subtitle1" style={{ fontSize: 12 }} align={'center'}>
          {SOFT_NAME} by EFSOFT v${VERSION}
        </Typography>
      </Column>
    </div>
  );
};

Footer.propTypes = {
  classes: object
};

export default withStyles(styles)(Footer);
